// eslint-disable-next-line import/no-extraneous-dependencies
import * as z from 'zod';
import { ZodTypeAny } from 'zod';

const linkSchema = z.object({
  objectType: z.string().min(2),
  objectId: z.string().min(1),
});

// entitlement
export const Entitlement = z.object({
  type: z.string(),
  name: z.string(),
  permissions: z.array(z.string()),
});

// base asset
export const Asset = z.object({
  assetId: z.string().uuid(),
  versionId: z.string().uuid(),
  name: z.string().min(2),
  assetActive: z.boolean(),
  versionNumber: z.number().positive(),
  assetMetadata: z.object({
    subClass: z.string(),
    runtime: z.string(),
    description: z.string().optional(),
  }),
  versionMetadata: z.object({}).optional(),
  versionTags: z.array(z.string()).optional(),
  assetLinks: z.array(linkSchema).optional(),
  versionLinks: z.array(linkSchema).optional(),
  artifactIds: z.array(z.string()).min(1),
  createdByUserId: z.string(),
  createTimestamp: z.string(),
  entitlements: z.array(Entitlement).default([]),
});

export const AssetCreate = Asset.omit({
  assetId: true,
  versionId: true,
  versionNumber: true,
  assetActive: true,
  createdByUserId: true,
  createTimestamp: true,
});

export const AssetCreateVersion = Asset.pick({
  artifactIds: true,
  versionMetadata: true,
});

export const AssetCreateResponse = Asset.pick({
  assetId: true,
  versionId: true,
});

export const AssetUpdateResponse = Asset.pick({
  assetId: true,
});

export const AssetGetResponse = Asset.omit({
  artifactIds: true,
}).extend({
  artifacts: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        type: z.string(),
        url: z.string(),
        active: z.boolean(),
        metadata: z.object({}).passthrough().optional(),
        createTimestamp: z.string(),
      })
    )
    .default([]),
});

export const AssetSearchOptions = z.object({
  from: z.number().default(0).optional(),
  size: z.number().default(10),
  name: z.string().optional(),
  active: z.boolean().default(true).optional(),
  createdByUserId: z.string().optional(),
  runtime: z.string().optional(),
});

// TODO: remove this later, this is not needed.
export const numericString = (schema: ZodTypeAny) =>
  z.preprocess(a => {
    if (typeof a === 'string') {
      return parseInt(a, 10);
      // eslint-disable-next-line no-else-return
    } else if (typeof a === 'number') {
      return a;
    }
    return undefined;
  }, schema);

export const AssetSearchResponse = z.object({
  results: z.array(
    z.object({
      assetId: z.string().uuid(),
      name: z.string(),
      active: z.boolean(),
      thumbnail: z.string().url().optional(),
      createdByUserId: z.string(),
      createTimestamp: z.string(),
      currentUserAccess: z.array(z.string()).max(3),
      metadata: z
        .object({
          subClass: z.string().optional(),
          runtime: z.string().optional(),
          description: z.string().optional(),
        })
        .passthrough(),
      version: z.object({
        versionId: z.string().uuid(),
        versionNumber: z.number(),
        createTimestamp: z.string(),
        artifactReferences: z.array(
          z.object({
            artifactId: z.string(),
            name: z.string(),
            fileType: z.string(),
            contentType: z.string(),
            contentUrl: z.string(),
            metadata: z.object({}).passthrough().optional(),
          })
        ),
      }),
    })
  ),
  pagination: z.object({
    from: z.number(),
    size: numericString(z.number()),
    total: z.number(),
  }),
});

export const AssetVersions = z.array(
  z.object({
    active: z.boolean(),
    artifactReferences: z.array(
      z.object({
        artifactId: z.string(),
        name: z.string(),
        fileType: z.string(),
        contentType: z.string(),
        contentUrl: z.string(),
        metadata: z.object({}).passthrough().optional(),
      })
    ),
    createTimestamp: z.string(),
    createdByUserId: z.string(),
    links: z.array(
      z.object({
        objectVersionId: z.string(),
        label: z.string(),
        objectId: z.string(),
        objectType: z.string(),
      })
    ),
    metadata: z
      .object({
        description: z.string().optional(),
        versionNotes: z.string().optional(),
      })
      .passthrough()
      .optional(),
    tags: z.array(z.string()),
    versionId: z.string().uuid(),
    versionNumber: z.number(),
  })
);

// entitlement
export type Entitlement = z.infer<typeof Entitlement>;

// base schemas, used for library methods
export type Asset = z.infer<typeof Asset>;
export type AssetCreate = z.infer<typeof AssetCreate>;
export type AssetCreateVersion = z.infer<typeof AssetCreateVersion>;
export type AssetVersions = z.infer<typeof AssetVersions>;
export type AssetSearchOptions = z.infer<typeof AssetSearchOptions>;

// responses
export type AssetCreateResponse = z.infer<typeof AssetCreateResponse>;
export type AssetUpdateResponse = z.infer<typeof AssetUpdateResponse>;
export type AssetGetResponse = z.infer<typeof AssetGetResponse>;
export type AssetSearchResponse = z.infer<typeof AssetSearchResponse>;
