/* eslint-disable no-shadow */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as qs from 'qs';
import { AnyZodObject } from 'zod';
import { ArtifactCreate, ArtifactCreateResponse } from './types/artifact';
import { ServiceError } from './serviceError';
import {
  AssetCreate,
  AssetCreateResponse,
  AssetCreateVersion,
  AssetGetResponse,
  AssetSearchOptions,
  AssetSearchResponse,
  AssetUpdateResponse,
  AssetVersions,
  Entitlement,
} from './types/asset';

export enum AuroraBaseUrl {
  TEST = 'https://api.test.dpc.nike.io/',
  PRODUCTION = 'https://api.prod.dpc.nike.io/',
}

interface Logger {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (message: string, ...props: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: (message: string, ...props: any[]) => void;
}

export interface AuroraConfigOptions {
  baseUrl: AuroraBaseUrl;
  logger?: Logger;
}

interface AuroraSchemas {
  Get: AnyZodObject;
}

export abstract class AuroraClient<
  C extends AssetCreate,
  CV extends AssetCreateVersion,
  G extends AssetGetResponse
> {
  private readonly logger: Logger;

  private readonly http: AxiosInstance;

  private readonly assetClass: string;

  private readonly searchIncludedFields: string[] = [];

  private readonly readSchemas: AuroraSchemas;

  constructor(options: AuroraConfigOptions, assetClass: string, readSchemas: AuroraSchemas) {
    this.logger = options.logger || console;
    this.readSchemas = readSchemas;
    this.assetClass = assetClass;
    this.http = axios.create({ baseURL: options.baseUrl });

    // logging
    this.http.interceptors.request.use(config => {
      this.logger.log('Aurora Request', {
        method: config.method,
        url: `${options.baseUrl}${config.url}`,
        params: config.params,
        data: config.data,
      });
      return config;
    });

    // error handler
    this.http.interceptors.response.use(
      response => response,
      error => this.handleError(error)
    );
  }

  // artifacts
  async createArtifact(
    artifact: ArtifactCreate,
    authToken: string
  ): Promise<ArtifactCreateResponse> {
    const { data } = await this.request(
      {
        method: 'post',
        url: 'via/v1/dpc/artifacts',
        data: artifact,
      },
      authToken
    );
    return ArtifactCreateResponse.parse(data);
  }

  async getArtifactBuffer(artifactId: string, authToken: string): Promise<string> {
    const { data } = await this.request(
      {
        method: 'get',
        url: `via/v1/dpc/artifacts/${artifactId}`,
        responseType: 'arraybuffer',
      },
      authToken
    );
    return data;
  }

  async getArtifactUrl(artifactId: string, authToken: string): Promise<string> {
    const {
      data: { location },
    } = await this.request(
      {
        method: 'get',
        url: `via/v1/dpc/artifacts/${artifactId}?redirect=false`,
      },
      authToken
    );
    return location;
  }

  // assets
  async createAsset(asset: C, authToken: string): Promise<AssetCreateResponse> {
    const { data } = await this.request(
      {
        method: 'post',
        url: `via/v1/dpc/assets/${this.assetClass}`,
        data: asset,
      },
      authToken
    );
    return AssetCreateResponse.parse(data);
  }

  async createAssetVersion(
    assetId: string,
    versionId: string,
    assetVersion: CV,
    authToken: string
  ): Promise<AssetCreateResponse> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { versionMetadata: omitted, ...fields } = assetVersion;
    const formattedPayload = {
      ...fields,
      // rewrite versionMetadata --> metadata for sanity and following the Aurora API spec
      // TODO: I might move this logic out later and change .parse to post process the object.
      metadata: assetVersion.versionMetadata,
    };

    const { data } = await this.request(
      {
        method: 'post',
        url: `via/v1/dpc/assets/${this.assetClass}/${assetId}/versions/${versionId}`,
        data: formattedPayload,
      },
      authToken
    );
    return AssetCreateResponse.parse(data);
  }

  async publishAssetVersion(
    assetId: string,
    versionId: string,
    authToken: string
  ): Promise<AssetCreateResponse> {
    const { data } = await this.request(
      {
        method: 'put',
        url: `via/v1/dpc/assets/${this.assetClass}/${assetId}/versions/${versionId}`,
        data: {
          appendTags: ['PUBLISHED'],
        },
      },
      authToken
    );
    return AssetCreateResponse.parse(data);
  }

  async renameAsset(
    assetId: string,
    newName: string,
    authToken: string
  ): Promise<AssetUpdateResponse> {
    const { data } = await this.request(
      {
        method: 'put',
        url: `via/v1/dpc/assets/${this.assetClass}/${assetId}`,
        data: { name: newName },
      },
      authToken
    );

    return AssetUpdateResponse.parse(data);
  }

  async deleteAsset(assetId: string, authToken: string): Promise<AssetUpdateResponse> {
    const { data } = await this.request(
      {
        method: 'put',
        url: `via/v1/dpc/assets/${this.assetClass}/${assetId}`,
        data: {
          active: false,
        },
      },
      authToken
    );

    return AssetUpdateResponse.parse(data);
  }

  async updateAssetEntitlements(
    assetId: string,
    assetEntitlements: Entitlement[],
    authToken: string
  ): Promise<AssetUpdateResponse> {
    const { data } = await this.request(
      {
        method: 'put',
        url: `via/v1/dpc/assets/${this.assetClass}/${assetId}`,
        data: {
          entitlements: assetEntitlements,
        },
      },
      authToken
    );

    return AssetUpdateResponse.parse(data);
  }

  async getAllVersions(assetId: string, authToken: string): Promise<AssetVersions> {
    const { data } = await this.request(
      {
        method: 'get',
        url: `via/v1/dpc/assets/${this.assetClass}/${assetId}/versions`,
      },
      authToken
    );

    return AssetVersions.parse(data);
  }

  async getAssetVersion(assetId: string, versionId: string, authToken: string): Promise<G> {
    const { data } = await this.request(
      {
        method: 'get',
        url: `via/v1/dpc/assets/${this.assetClass}/${assetId}/versions/${versionId}`,
      },
      authToken
    );
    return this.readSchemas.Get.parse(data) as G;
  }

  async getAssetLatest(assetId: string, authToken: string): Promise<G> {
    return this.getAssetVersion(assetId, 'latest', authToken);
  }

  async search(options: AssetSearchOptions, authToken: string): Promise<AssetSearchResponse> {
    // TODO: make the included fields config.
    const query = {
      'terms.assetClass': this.assetClass,
      'query.name': options.name,
      'terms.active': options.active,
      'terms.createdByUserId': options.createdByUserId,
      'terms.metadata.runtime': options.runtime,
      'fields.includes': [
        'assetId',
        'name',
        'active',
        'thumbnail',
        'createdByUserId',
        'createTimestamp',
        'metadata',
        'version.versionId',
        'version.versionNumber',
        'version.createTimestamp',
        'version.artifactReferences',
      ],
      'pagination.from': options.from,
      'pagination.size': options.size,
    };
    const queryString = qs.stringify(query, {
      skipNulls: true,
      allowDots: true,
      arrayFormat: 'indices',
    });
    const { data } = await this.request(
      {
        method: 'get',
        url: `search/v2/dpc/allassets?${queryString}`,
      },
      authToken
    );
    return AssetSearchResponse.parse(data);
  }

  async request(config: AxiosRequestConfig, authToken: string): Promise<AxiosResponse> {
    return this.http.request({
      ...config,
      headers: { Authorization: `Bearer ${authToken}` },
    });
  }

  // handle all service errors
  handleError(error: AxiosError) {
    this.logger.error(error.message, {
      method: error.config?.method,
      url: error.config?.url,
      response: error.response?.data,
    });
    throw new ServiceError(error);
  }
}
