import * as ReactDOM from 'react-dom/client';

import App from './app/app';
import { AppProvider } from './providers/app-provider';

// This is to make click events work in an adobe extension on Mac for Spectrum
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
delete window.PointerEvent;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const script = document.createElement('script');
script.src = 'https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/rhino3dm.min.js';
script.addEventListener('load', () => {
  root.render(
    <AppProvider>
      <App />
    </AppProvider>
  );
});
document.body.appendChild(script);
