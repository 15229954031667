import {
  Algorithm,
  AlgorithmControls,
  AlgorithmSolveResponse,
  GrasshopperClient,
  IGrasshopperClient,
  Modifier,
} from '@nike.picc.dam/grasshopper-client';
import { QueryClient } from '@tanstack/react-query';
import { AlgorithmSettings } from '../app/ai-algorithm-editor/types';

export interface IGrasshopperClientService {
  algorithms: Algorithm[];
  modifiers: Modifier[];
  getAlgorithmControls: IGrasshopperClient['getAlgorithmControls'];
  getModifierControls: IGrasshopperClient['getModifierControls'];
  solveAlgorithm: (
    algorithmSettings: AlgorithmSettings,
    authToken: string
  ) => Promise<AlgorithmSolveResponse>;
}

export class GrasshopperClientService implements IGrasshopperClientService {
  private queryClient: QueryClient;

  private grasshopperClient: IGrasshopperClient;

  algorithms: Algorithm[];

  modifiers: Modifier[];

  constructor(
    queryClient: QueryClient,
    grasshopperClient: IGrasshopperClient = new GrasshopperClient()
  ) {
    this.queryClient = queryClient;
    this.grasshopperClient = grasshopperClient;

    this.algorithms = this.grasshopperClient.getAlgorithms();
    this.modifiers = this.grasshopperClient.getModifiers();
  }

  getAlgorithmControls(algorithm: Algorithm): AlgorithmControls[] {
    return this.grasshopperClient.getAlgorithmControls(algorithm);
  }

  getModifierControls(modifier: Modifier): AlgorithmControls[] {
    return this.grasshopperClient.getModifierControls(modifier);
  }

  async solveAlgorithm(algorithmSettings: AlgorithmSettings, authToken: string) {
    let response = await this.queryClient.fetchQuery(
      ['grasshopper-client-solve', algorithmSettings.type, algorithmSettings.inputs, authToken],
      () =>
        this.grasshopperClient.solveAlgorithm(
          algorithmSettings.type,
          algorithmSettings.inputs,
          authToken
        ),
      // Since the solve response will always be unchanged with the same args,
      // we can set this data to always use the previously cached response
      // instead of making a new call
      { staleTime: Infinity }
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const modifier of algorithmSettings.modifiers) {
      // eslint-disable-next-line no-await-in-loop
      response = await this.queryClient.fetchQuery(
        ['grasshopper-client-solve-algorithm', modifier.type, modifier.inputs, authToken, response],
        // eslint-disable-next-line no-loop-func
        () =>
          this.grasshopperClient.solveAlgorithm(
            modifier.type,
            modifier.inputs,
            authToken,
            response
          ),
        { staleTime: Infinity }
      );
    }

    return response;
  }
}
