/* eslint-disable */
// These are scripts that will be run in AI (it's JavaScript but it's not run by a web browser)
// For details see: https://ai-scripting.docsforadobe.dev/
//
// Information for XMP is hard to find; start here...
// https://github.com/adobe/XMP-Toolkit-SDK/blob/main/docs/XMPProgrammersGuide.pdf
// https://www.adobe.com/content/dam/acom/en/devnet/illustrator/pdf/javascript_tools_guide_cs3.pdf
module.exports = {
  readXMP(tag) {
    var doc = app.activeDocument;

    var data = '{}';

    // There is currently a bug in Adobe Illustrator 2021 on MacOS that prevents this from working
    try {
      // Load the XMP Script library (if needed)
      if (ExternalObject.AdobeXMPScript == undefined) {
        ExternalObject.AdobeXMPScript = new ExternalObject('lib:AdobeXMPScript');
      }

      var xmp = new XMPMeta(doc.XMPString);
      XMPMeta.registerNamespace('io.nike.chariot', 'chariot');

      if (xmp.doesPropertyExist('io.nike.chariot', tag)) {
        data = xmp.getProperty('io.nike.chariot', tag).value;
      }
    } catch (e) {}

    return data;
  },

  writeXMP(tag, data) {
    var doc = app.activeDocument;

    // There is currently a bug in Adobe Illustrator 2021 on MacOS that prevents this from working
    try {
      // Load the XMP Script library (if needed)
      if (ExternalObject.AdobeXMPScript == undefined) {
        ExternalObject.AdobeXMPScript = new ExternalObject('lib:AdobeXMPScript');
      }

      var xmp = new XMPMeta(doc.XMPString);
      XMPMeta.registerNamespace('io.nike.chariot', 'chariot');
      xmp.setProperty('io.nike.chariot', tag, data);
      doc.XMPString = xmp.serialize(XMPConst.SERIALIZE_USE_COMPACT_FORMAT);

      // This file needs to be saved to keep meta data
      doc.saved = false;
    } catch (e) {}
  },
};
