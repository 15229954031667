import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';
import axios from 'axios';
import { Ok, Result } from 'ts-results';

import {
  Aurora,
  AuroraBaseUrl,
  ComputationDefinitionCreate,
  ComputationDefinitionCreateVersion,
  Entitlement,
  AssetUpdateResponse,
  AssetGetResponse,
  AssetSearchOptions,
} from '@nike.picc.dam/aurora';
import { Workflow, CreateOptions, VersionOptions } from './types';
import { WorkflowResult } from './workflows/types';

export function composureSdk(): string {
  return 'composure-sdk';
}

export interface ComposureResponse {
  id: string;
  message: string;
}

export interface SolveResponse {
  [key: string]: any;
}

export type Environment = 'local' | 'test' | 'prod';
export type ComposureUrl = 'http://localhost:80' | 'https://';

export class ComposureClient {
  private oktaToken: string;

  private composureBaseUrl: string;

  private adGroup: string;

  private auroraBaseUrl: AuroraBaseUrl;

  private definitionsRoute = 'api/v1/definitions';

  private workflowsRoute = 'api/v1/workflows';

  constructor(oktaToken: string, env: Environment) {
    this.oktaToken = oktaToken;
    switch (env) {
      case 'local': {
        this.composureBaseUrl = 'http://localhost:80';
        this.auroraBaseUrl = AuroraBaseUrl.TEST;
        this.adGroup = 'App.Composure.Test.Users';
        break;
      }
      case 'test': {
        this.composureBaseUrl = 'https://api.composure-test.nike.io';
        this.auroraBaseUrl = AuroraBaseUrl.TEST;
        this.adGroup = 'App.Composure.Test.Users';
        break;
      }
      case 'prod': {
        this.composureBaseUrl = 'https://api.composure.nike.io';
        this.auroraBaseUrl = AuroraBaseUrl.PRODUCTION;
        this.adGroup = 'App.Composure.Prod.Users';
        break;
      }
      default: {
        throw new Error(
          'Did not specify an environment variable of either "local" | "test" | "prod"'
        );
      }
    }
  }

  upload(grasshopperFile: File): TE.TaskEither<Error, ComposureResponse> {
    return TE.tryCatch(
      () =>
        fetch(this.composureBaseUrl + this.definitionsRoute, {
          method: 'POST',
          body: grasshopperFile,
        }).then(res => {
          if (!res.ok) {
            throw new Error(`fetch failed with status: ${res.status}`);
          }
          return res.json();
        }),
      E.toError
    );
  }

  /**
   * Allows you to solve against a Grasshopper definition uploaded to Composure with some object
   * @param id
   * @param params
   */
  async solve(id: string, params: unknown): Promise<Result<SolveResponse, Error>> {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${this.oktaToken}`,
      },
    };
    try {
      const response = await axios.post(
        `${this.composureBaseUrl}/${this.definitionsRoute}/${id}/solve`,
        params,
        axiosConfig
      );
      return Ok(response.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  async solveVersion(
    id: string,
    versionId: string,
    params: unknown
  ): Promise<Result<SolveResponse, Error>> {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${this.oktaToken}`,
      },
    };
    try {
      const response = await axios.post(
        `${this.composureBaseUrl}/${this.definitionsRoute}/${id}/versions/${versionId}/solve`,
        params,
        axiosConfig
      );
      return Ok(response.data);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  async createDefinition(
    options: CreateOptions
  ): Promise<{ assetId?: string; versionId?: string }> {
    console.log('creating new definition with options: ', options);
    const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });

    const artifactPostResults = [];

    const compDefArtifact = await auroraClient.ComputationDefinition.createArtifact(
      {
        name: options.name,
        fileType: options.fileType,
        contentType: 'application/octet-stream',
      },
      options.authToken
    );

    await axios.put(compDefArtifact.location, options.file, {
      headers: { 'Content-Type': 'application/octet-stream' },
    });

    const compDefIOArtifact = await auroraClient.ComputationDefinition.createArtifact(
      {
        name: 'io.json',
        fileType: 'JSON',
        contentType: 'application/json',
      },
      options.authToken
    );

    await axios.put(compDefIOArtifact.location, options.io, {
      headers: { 'Content-Type': 'application/json' },
    });

    artifactPostResults.push(compDefArtifact);
    artifactPostResults.push(compDefIOArtifact);
    const createEntitlement: Entitlement = {
      type: 'group',
      name: 'App.Composure.Developers',
      permissions: ['READ', 'WRITE', 'ADMIN'],
    };

    options.entitlements.push(createEntitlement);

    const cd = ComputationDefinitionCreate.parse({
      name: options.name,
      artifactIds: [...artifactPostResults.map(x => x.id)],
      versionMetadata: {},
      assetMetadata: {
        description: options.description,
        methodOfMake: options.methodOfMake,
      },
      entitlements: options.entitlements,
    });
    const asset = await auroraClient.ComputationDefinition.createAsset(cd, options.authToken);
    return asset;
  }

  async createDefinitionVersion(
    options: VersionOptions
  ): Promise<{ assetId?: string; versionId?: string }> {
    const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });
    const originalAsset = await auroraClient.ComputationDefinition.getAssetLatest(
      options.id,
      options.authToken
    );

    const artifactPostResults = [];

    const compDefArtifact = await auroraClient.ComputationDefinition.createArtifact(
      {
        name: options.name,
        fileType: options.fileType,
        contentType: 'application/octet-stream',
      },
      options.authToken
    );

    await axios.put(compDefArtifact.location, options.file, {
      headers: { 'Content-Type': 'application/octet-stream' },
    });

    const compDefIOArtifact = await auroraClient.ComputationDefinition.createArtifact(
      {
        name: 'io.json',
        fileType: 'JSON',
        contentType: 'application/json',
      },
      options.authToken
    );

    await axios.put(compDefIOArtifact.location, options.io, {
      headers: { 'Content-Type': 'application/json' },
    });

    artifactPostResults.push(compDefArtifact);
    artifactPostResults.push(compDefIOArtifact);

    const cdv = ComputationDefinitionCreateVersion.parse({
      name: options.name,
      artifactIds: [...artifactPostResults.map(x => x.id)],
      versionMetadata: {
        description: options.description,
        versionNotes: options.versionNotes,
      },
    });

    const asset = await auroraClient.ComputationDefinition.createAssetVersion(
      options.id,
      originalAsset.versionId,
      cdv,
      options.authToken
    );
    return asset;
  }

  async createWorkflow(
    workflow: Omit<Workflow, 'id' | 'versionId' | 'entitlements' | 'isPublic' | 'versionNumber'>
  ) {
    try {
      const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });

      const worflowArtifact = await auroraClient.ComputationDefinition.createArtifact(
        {
          name: 'workflow.json',
          fileType: 'JSON',
          contentType: 'application/json',
        },
        this.oktaToken
      );

      await axios.put(worflowArtifact.location, workflow, {
        headers: { 'Content-Type': 'application/json' },
      });

      const artifactPostResults = [worflowArtifact];

      const workflowDefinition = ComputationDefinitionCreate.parse({
        name: workflow.name,
        artifactIds: [...artifactPostResults.map(result => result.id)],
        versionMetadata: {},
        assetMetadata: {
          runtime: 'workflow',
        },
        entitlements: [
          {
            type: 'group',
            name: 'App.Composure.Developers',
            permissions: ['READ' as const, 'WRITE' as const, 'ADMIN' as const],
          },
        ],
      });

      return auroraClient.ComputationDefinition.createAsset(workflowDefinition, this.oktaToken);
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  async getWorkflowVersion(id: string, versionId: string): Promise<Workflow> {
    try {
      const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });

      const workflowDefinition = await auroraClient.ComputationDefinition.getAssetVersion(
        id,
        versionId,
        this.oktaToken
      );

      const workflowArtifact = await axios.get(workflowDefinition.artifacts[0].url, {
        headers: {
          Authorization: `Bearer ${this.oktaToken}`,
        },
      });

      return {
        id,
        versionId,
        name: workflowDefinition.name,
        steps: workflowArtifact.data.steps,
        entitlements: workflowDefinition.entitlements || [],
        versionNumber: workflowDefinition.versionNumber,
        isPublic: this.checkForPublicEntitlement(workflowDefinition),
      };
    } catch (err) {
      console.log('error fetching workflow');
      return Promise.reject(err);
    }
  }

  async createWorkflowVersion(workflow: Workflow) {
    try {
      const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });

      const worflowArtifact = await auroraClient.ComputationDefinition.createArtifact(
        {
          name: 'workflow.json',
          fileType: 'JSON',
          contentType: 'application/json',
        },
        this.oktaToken
      );

      await axios.put(worflowArtifact.location, workflow, {
        headers: { 'Content-Type': 'application/json' },
      });

      const artifactPostResults = [worflowArtifact];

      const workflowDefinition = ComputationDefinitionCreateVersion.parse({
        name: workflow.name,
        artifactIds: [...artifactPostResults.map(result => result.id)],
        versionMetadata: {},
        assetMetadata: {
          runtime: 'workflow',
        },
      });

      return await auroraClient.ComputationDefinition.createAssetVersion(
        workflow.id,
        workflow.versionId,
        workflowDefinition,
        this.oktaToken
      );
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }

  async persistWorkflowResult(workflowResult: WorkflowResult) {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${this.oktaToken}`,
      },
    };
    try {
      const presignedUrls = await axios.post(
        `${this.composureBaseUrl}/${this.workflowsRoute}/${workflowResult.workflowId}/versions/${workflowResult.versionId}/results`,
        workflowResult,
        axiosConfig
      );
      return presignedUrls.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async publishAssetVersion(id: string, versionId: string) {
    try {
      const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });

      await auroraClient.ComputationDefinition.publishAssetVersion(id, versionId, this.oktaToken);
    } catch (err) {
      console.log(err);
    }
  }

  async updateAssetEntitlements(
    id: string,
    entitlements: Entitlement[]
  ): Promise<AssetUpdateResponse> {
    try {
      const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });

      return await auroraClient.ComputationDefinition.updateAssetEntitlements(
        id,
        entitlements,
        this.oktaToken
      );
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async deleteAsset(id: string) {
    try {
      const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });

      await auroraClient.ComputationDefinition.deleteAsset(id, this.oktaToken);
    } catch (err) {
      console.log(err);
    }
  }

  async renameAsset(id: string, newName: string) {
    try {
      const auroraClient = new Aurora({ baseUrl: this.auroraBaseUrl });

      await auroraClient.ComputationDefinition.renameAsset(id, newName, this.oktaToken);
    } catch (err) {
      console.log(err);
    }
  }

  checkForPublicEntitlement(definition: AssetGetResponse): boolean {
    if (definition.entitlements) {
      if (definition.entitlements.length === 0) return true;

      const publicEntitlement = definition.entitlements.find(
        (x: { name: string }) => x.name === this.adGroup
      );

      if (publicEntitlement) {
        return publicEntitlement.permissions.includes('READ');
      }
    }

    return false;
  }
}
