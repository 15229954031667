import axios from 'axios';
import * as O from 'fp-ts/Option';

import {
  SwitchBack,
  SplitSwitchBack,
  Sine,
  Noise,
  Square,
  Sawtooth,
  Triangle,
  PinchAndSpread,
  RhinoResponseValue,
  AlgorithmSolveResponse,
} from './schema';

export type SupportedDefinitions =
  | SwitchBack
  | SplitSwitchBack
  | Sine
  | Noise
  | Square
  | Triangle
  | Sawtooth
  | PinchAndSpread;

export function AppendRHIN(inputObj: Record<string, unknown>) {
  return Object.entries(inputObj).reduce((acc, [key, value]) => {
    acc[`RH_IN:${key}`] = value;
    return acc;
  }, {} as Record<string, unknown>);
}

const azureTestUrl = 'https://nxt-rhc-test-staging.n5mt01.nike-cloud.com';

export function RemoveRHOUT(body: Record<string, unknown>) {
  return Object.entries(body)
    .map(([key, value]) => {
      const input: string = key.slice(7);
      return { [input]: value };
    })
    .reduce((obj, curr) => ({ ...obj, ...curr }), {});
}

export function parseAzureTestResponse(data: RhinoResponseValue): AlgorithmSolveResponse {
  // Walk the nested array and fold all the values into a single object, converting Rhino return types where necessary
  // {[ParamName: 'RH_OUT:Vertices', InnerTree: [{ type: 'Rhino.Geometry.Point3d', data: '{"X": 0, "Y": 0, "Z": 0}}]]}
  const parsedRhinoResponse = data.values.reduce((acc, curr) => {
    const parsedArray: (string | number)[] = [];
    Object.values(curr.InnerTree).forEach(val => {
      val.forEach(x => {
        if (x.type === 'Rhino.Geometry.Point3d') {
          parsedArray.push(JSON.parse(x.data));
          return;
        }
        if (x.type === 'System.Double') {
          parsedArray.push(parseFloat(x.data));
          return;
        }
        if (x.type === 'System.Int32') {
          parsedArray.push(parseInt(x.data, 10));
          return;
        }
        if (x.type === 'System.String') {
          // Current algorithms return 'Side' as a string but in quotes
          // this removes the quotes from the string
          parsedArray.push(x.data.replace(/^"(.*)"$/, '$1'));
          return;
        }
        parsedArray.push(x.data);
      });
    });
    return { ...acc, [curr.ParamName]: parsedArray };
  }, {});
  const rhOutRemoved = RemoveRHOUT(parsedRhinoResponse);
  // TODO: return a generic type here for more general use of Grasshopper algorithms
  return AlgorithmSolveResponse.parse(rhOutRemoved);
}

export type SupportControlTypes = 'Slider' | 'Dropdown';

// interface that describes how an AlgorithmInput can be rendered
// into some kind of UI

export type SliderControls = {
  tag: 'Slider';
  name: string;
  defaultValue: number;
  min: number;
  max: number;
  step: number;
};

export type DropdownControls = {
  tag: 'Dropdown';
  name: string;
  defaultValue: string;
  options: string[];
};

export type AlgorithmControls = SliderControls | DropdownControls;

type SliderInput = Omit<SliderControls, 'defaultValue'> & { value: SliderControls['defaultValue'] };
type DropdownInput = Omit<DropdownControls, 'defaultValue'> & {
  value: DropdownControls['defaultValue'];
};
export type AlgorithmInput = SliderInput | DropdownInput;

// TODO: this may not work with some modifiers and maps, their formatting is not consistent
export const formToRhinoInput = (inputs: AlgorithmInput[]): Record<string, unknown> =>
  // create `inputs` property value
  inputs.reduce(
    (acc, curr) => ({ ...acc, [`RH_IN:${curr.name.replace(/\s/g, '')}`]: curr.value }),
    {}
  );

export type Algorithm =
  | { name: 'Switchback'; tag: 'switchback.ghx' }
  | { name: 'Split Switchback'; tag: 'splitSwitchback.ghx' };

export type Modifier =
  | { name: 'Square Wave'; tag: 'squareWave.ghx' }
  | { name: 'Noise Wave'; tag: 'noiseWave.ghx' }
  | { name: 'Sine Wave'; tag: 'sineWave.ghx' }
  | { name: 'Triangle Wave'; tag: 'triangleWave.ghx' }
  | { name: 'Sawtooth Wave'; tag: 'sawtoothWave.ghx' }
  | { name: 'Pinch and Spread'; tag: 'pinchSpread.ghx' };

export interface IGrasshopperClient {
  getAlgorithms(): Algorithm[];
  getModifiers(): Modifier[];
  getAlgorithmControls(algorithm: Algorithm): AlgorithmControls[];
  getModifierControls(modifier: Modifier): AlgorithmControls[];
  solveAlgorithm(
    algorithm: Algorithm['tag'] | Modifier['tag'],
    inputs: AlgorithmInput[],
    authToken: string,
    prevResponse?: AlgorithmSolveResponse
  ): Promise<AlgorithmSolveResponse>;
}

export class GrasshopperClient implements IGrasshopperClient {
  private supportedAlgorithms: Algorithm[] = [
    { name: 'Switchback', tag: 'switchback.ghx' },
    { name: 'Split Switchback', tag: 'splitSwitchback.ghx' },
  ];

  // TODO: add maelstrom
  private supportedModifiers: Modifier[] = [
    { name: 'Square Wave', tag: 'squareWave.ghx' },
    { name: 'Noise Wave', tag: 'noiseWave.ghx' },
    { name: 'Sine Wave', tag: 'sineWave.ghx' },
    { name: 'Triangle Wave', tag: 'triangleWave.ghx' },
    { name: 'Sawtooth Wave', tag: 'sawtoothWave.ghx' },
    { name: 'Pinch and Spread', tag: 'pinchSpread.ghx' },
  ];

  // Algorithm Controls for both Split Switchback and Switchback
  // /////////////////////////////////////////////////////////////
  private splitSwitchbackControls: AlgorithmControls[] = [
    {
      tag: 'Slider',
      name: 'Right Divisions',
      min: 1,
      max: 40,
      defaultValue: 20,
      step: 1.0,
    },
    {
      tag: 'Slider',
      name: 'Left Divisions',
      min: 1,
      max: 40,
      defaultValue: 15,
      step: 1.0,
    },
    {
      tag: 'Slider',
      name: 'Right Offset',
      min: 0.1,
      max: 10,
      defaultValue: 5.47,
      step: 0.01,
    },
    {
      tag: 'Slider',
      name: 'Left Offset',
      min: 0.1,
      max: 10,
      defaultValue: 1.67,
      step: 0.01,
    },
    {
      tag: 'Slider',
      name: 'Right Base Offset',
      min: 0.1,
      max: 20,
      defaultValue: 13.22,
      step: 0.01,
    },
    {
      tag: 'Slider',
      name: 'Left Base Offset',
      min: 0.1,
      max: 20,
      defaultValue: 10.11,
      step: 0.01,
    },
  ];

  private switchbackControls: AlgorithmControls[] = [
    {
      tag: 'Slider',
      name: 'Divisions',
      min: 3,
      max: 30,
      defaultValue: 27,
      step: 1,
    },
    {
      tag: 'Slider',
      name: 'Offset',
      min: 0.1,
      max: 10,
      defaultValue: 4.98,
      step: 0.01,
    },
  ];

  private waveformControls: AlgorithmControls[] = [
    // Hide for now, until map functionality is complete
    // { tag: 'Dropdown', name: 'Map', defaultValue: defaultMap, options: [defaultMap] },
    {
      tag: 'Slider',
      name: 'Phase',
      min: -3,
      max: 3,
      defaultValue: 3,
      step: 0.01,
    },
    {
      tag: 'Slider',
      name: 'Amplitude',
      min: 0,
      max: 10,
      defaultValue: 1.9,
      step: 0.1,
    },
    {
      tag: 'Slider',
      name: 'Frequency',
      min: 1.0,
      max: 10,
      defaultValue: 7.0,
      step: 1,
    },
  ];

  // TODO: separate the controls for an algorithm that have user input v whats needed to solve in rhino
  private noiseWaveControls: AlgorithmControls[] = [
    ...this.waveformControls,
    {
      tag: 'Slider',
      name: 'Random Seed',
      min: 1,
      max: 100,
      defaultValue: 50,
      step: 1,
    },
    {
      tag: 'Slider',
      name: 'Octaves',
      min: 0.1,
      max: 10,
      defaultValue: 2.0,
      step: 0.01,
    },
    {
      tag: 'Slider',
      name: 'Lacunarity',
      min: 0.1,
      max: 10,
      defaultValue: 2.8,
      step: 0.1,
    },
    {
      tag: 'Slider',
      name: 'Persistence',
      min: 0.1,
      max: 1.0,
      defaultValue: 0.3,
      step: 0.01,
    },
  ];

  private squareWaveControls: AlgorithmControls[] = [
    ...this.waveformControls,
    {
      tag: 'Slider',
      name: 'Square Width',
      min: 0.1,
      max: 10,
      defaultValue: 0.5,
      step: 0.1,
    },
  ];

  private sineWaveControls: AlgorithmControls[] = [...this.waveformControls];

  private triangleWaveControls: AlgorithmControls[] = [...this.waveformControls];

  private sawtoothWaveControl: AlgorithmControls[] = [
    ...this.waveformControls,
    {
      tag: 'Slider',
      name: 'Square Width',
      min: 0.1,
      max: 10,
      defaultValue: 0.5,
      step: 0.1,
    },
  ];

  private pinchAndSpreadControls: AlgorithmControls[] = [
    {
      tag: 'Slider',
      name: 'Radius',
      min: 1.0,
      max: 100.0,
      defaultValue: 20.0,
      step: 0.1,
    },
    {
      tag: 'Slider',
      name: 'Strengths',
      min: -1.0,
      max: 1.0,
      defaultValue: -0.68,
      step: 0.1,
    },
    {
      tag: 'Slider',
      name: 'Falloffs',
      min: 0.0,
      max: 1.0,
      defaultValue: 1.0,
      step: 0.01,
    },
    {
      tag: 'Slider',
      name: 'Power',
      min: 0.1,
      max: 10.0,
      defaultValue: 1.81,
      step: 0.01,
    },
    {
      tag: 'Slider',
      name: 'Smoothness',
      min: 0.1,
      max: 1.0,
      defaultValue: 1.0,
      step: 0.01,
    },
    {
      tag: 'Slider',
      name: 'Sharpness',
      min: 0.1,
      max: 1.0,
      defaultValue: 0.0,
      step: 0.01,
    },
  ];

  getAlgorithms(): Algorithm[] {
    return this.supportedAlgorithms;
  }

  getModifiers(): Modifier[] {
    return this.supportedModifiers;
  }

  getAlgorithmControls(algorithm: Algorithm): AlgorithmControls[] {
    switch (algorithm.tag) {
      case 'splitSwitchback.ghx': {
        return this.splitSwitchbackControls;
      }
      case 'switchback.ghx': {
        return this.switchbackControls;
      }
      default: {
        return [];
      }
    }
  }

  getModifierControls(modifier: Modifier): AlgorithmControls[] {
    switch (modifier.tag) {
      case 'noiseWave.ghx': {
        return this.noiseWaveControls;
      }
      case 'sawtoothWave.ghx': {
        return this.sawtoothWaveControl;
      }
      case 'sineWave.ghx': {
        return this.sineWaveControls;
      }
      case 'squareWave.ghx': {
        return this.squareWaveControls;
      }
      case 'triangleWave.ghx': {
        return this.triangleWaveControls;
      }
      case 'pinchSpread.ghx': {
        return this.pinchAndSpreadControls;
      }
      default: {
        return [];
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async solveAlgorithm(
    algorithm: Algorithm['tag'] | Modifier['tag'],
    inputs: AlgorithmInput[],
    authToken: string,
    prevResponse?: AlgorithmSolveResponse
  ): Promise<AlgorithmSolveResponse> {
    // TODO: validate incoming data, if improper field shows up throw err
    const rhinoAlgorithmInput = { definition: algorithm, inputs: formToRhinoInput(inputs) };

    if (prevResponse) {
      const { Vertices, ...rest } = prevResponse;
      rhinoAlgorithmInput.inputs = {
        ...rhinoAlgorithmInput.inputs,
        ...AppendRHIN({ ...rest, Vertices: Vertices.map(vertex => JSON.stringify(vertex)) }),
      };
    }

    const request = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
      },
      url: `${azureTestUrl}/Solve`,
      data: rhinoAlgorithmInput,
      method: 'POST',
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    };
    console.log('Request: ', request);

    const response = await axios(request);
    const azureTestResponse = RhinoResponseValue.passthrough().parse(response.data);
    const parsedResponse = parseAzureTestResponse(azureTestResponse);
    return parsedResponse;
  }
}
