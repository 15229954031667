import { Item, View, MenuTrigger, Menu, ActionButton, Header, Flex } from '@adobe/react-spectrum';
import ShowMenu from '@spectrum-icons/workflow/ShowMenu';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@nike/eds';

const menuItems = [
  { name: 'Home', path: '/' },
  { name: 'Nectar', path: '/nectar' },
];

const backgroundColor = 'gray-100';

export function NavigationHeader() {
  const navigate = useNavigate();

  // Remove the current page from the menu options
  const filterMenuItems = menuItems.filter(element => element.path !== window.location.pathname);

  return (
    <Header position="sticky" top="size-0" zIndex={10}>
      <View height="size-500" backgroundColor={backgroundColor}>
        <Flex justifyContent="end" alignItems="center">
          {/* Setting hamburger menu to hidden for now. Change justifyConetent to space-between when enabling */}
          <View isHidden>
            <MenuTrigger>
              <ActionButton
                UNSAFE_style={{
                  border: 0,
                  backgroundColor: 'transparent',
                }}
              >
                <ShowMenu />
              </ActionButton>
              <Menu
                onAction={key => {
                  navigate(key.toString());
                }}
                items={filterMenuItems}
              >
                {item => <Item key={item.path}>{item.name}</Item>}
              </Menu>
            </MenuTrigger>
          </View>
          <Icon name="NikeSwoosh" size="l" color="#757575" />
        </Flex>
      </View>
    </Header>
  );
}

export default NavigationHeader;
