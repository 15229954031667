/* eslint-disable */
// These are scripts that will be run in AI (it's JavaScript but it's not run by a web browser)
// For details see: https://ai-scripting.docsforadobe.dev/
export const artboards = {
  getArtboardInfo() {
    var doc = app.activeDocument;
    var artboard = doc.artboards[0];

    var info = '{';
    info += '"name":"' + artboard.name + '",';
    info += '"left":' + artboard.artboardRect[0] + ',';
    info += '"top":' + -artboard.artboardRect[1] + ',';
    info += '"width":' + (artboard.artboardRect[2] - artboard.artboardRect[0]) + ',';
    info += '"height":' + (artboard.artboardRect[1] - artboard.artboardRect[3]);
    info += '}';

    return info;
  },

  getArtboards() {
    return app.activeDocument.artboards;
  },

  setArtboardRect(x, y, width, height) {
    var doc = app.activeDocument;
    var artboard = doc.artboards[0];
    var rect = doc.pathItems.rectangle(y, x, width, height);
    artboard.artboardRect = rect.geometricBounds;
    rect.remove();
  },
};
