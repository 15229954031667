import { View, Flex, ActionButton, Heading } from '@adobe/react-spectrum';
import ChevronRightIcon from '@spectrum-icons/workflow/ChevronRight';
import ChevronDownIcon from '@spectrum-icons/workflow/ChevronDown';
import DeleteOutlineIcon from '@spectrum-icons/workflow/DeleteOutline';
import { useEditorReducer } from './editor-reducer';
import { ModifierSettings } from './types';
import { Input } from './input';
import { useTsai } from '../../providers/tsai-provider';
import { tabOptions } from './constants';

type ModifierPanelProps = {
  index: number;
  modifier: ModifierSettings;
};

export function ModifierPanel({ index, modifier }: ModifierPanelProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useEditorReducer();
  const tsai = useTsai();

  const layer = state[state.selectedLayer];
  const algorithm = layer.selectedAlgorithm ? layer[layer.selectedAlgorithm] : undefined;

  const disableInput = layer.name === tabOptions[1].label && layer.syncEnabled;

  return (
    <View backgroundColor="gray-100" borderRadius="regular" paddingX="size-75">
      <Flex direction="column">
        <Flex alignItems="center" gap="size-75">
          <ActionButton
            isQuiet
            onPress={() => {
              dispatch({ type: 'TOGGLE_MODIFIER_IS_EXPANDED', index });
            }}
          >
            {modifier.isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </ActionButton>
          <Heading
            level={4}
            flexGrow={1}
            marginY="size-150"
            UNSAFE_style={{
              color: 'var(--spectrum-global-color-blue-700)',
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            {modifier.name}
          </Heading>
          <ActionButton
            isQuiet
            isDisabled={disableInput}
            onPress={() => {
              dispatch({ type: 'DELETE_MODIFIER', index });
            }}
          >
            <DeleteOutlineIcon />
          </ActionButton>
        </Flex>
        {modifier.isExpanded ? (
          <View paddingX="size-125" paddingBottom="size-250">
            <Flex direction="column" gap="size-125">
              {modifier.inputs.map(input => {
                const id = `${modifier.type}-${index}-${input.name}-${input.tag}-input`;
                return (
                  <Input
                    key={id}
                    id={id}
                    input={input}
                    disabled={disableInput}
                    onChange={async value => {
                      if (algorithm) {
                        //
                        // Filter in only Pinch and Spread modifers and then create an array of promises which we can then wait on
                        //
                        const promises = algorithm.modifiers
                          .filter(mod => mod.type === 'pinchSpread.ghx')
                          .map(async mod => ({
                            name: mod.name,
                            center: await tsai.getPinchAndSpreadCenters(mod.name),
                          }));
                        const results = await Promise.all(promises);

                        results.forEach(elem => {
                          const c = JSON.parse(elem.center);
                          const centerValue = JSON.stringify({
                            X: c[0].x,
                            Y: c[0].y,
                            Z: 0.0,
                          });
                          dispatch({
                            type: 'SET_PINCH_AND_SPREAD_CENTER',
                            layerName: elem.name,
                            center: centerValue,
                          });
                        });
                      }

                      dispatch({
                        type: 'SET_MODIFIER_INPUT_VALUE',
                        index,
                        name: input.name,
                        value: value as string | number,
                      });
                    }}
                  />
                );
              })}
            </Flex>
          </View>
        ) : null}
      </Flex>
    </View>
  );
}
