import { Flex } from '@adobe/react-spectrum';
import { tabOptions } from './constants';
import { useEditorReducer } from './editor-reducer';
import { Input, InputItem, InputLabel } from './input';
import { useTsai } from '../../providers/tsai-provider';
import { AlgorithmSettings } from './types';

async function InputDispatcher(
  name: string,
  value: string | number,
  dispatch: any,
  tsai: any,
  algorithm: AlgorithmSettings
) {
  // eslint-disable-next-line no-restricted-syntax
  for (const mod of algorithm.modifiers) {
    if (mod.type === 'pinchSpread.ghx') {
      // eslint-disable-next-line no-await-in-loop
      const centers = JSON.parse(await tsai.getPinchAndSpreadCenters(mod.name));
      const centerValue = JSON.stringify({ X: centers[0].x, Y: centers[0].y, Z: 0.0 });
      dispatch({
        type: 'SET_PINCH_AND_SPREAD_CENTER',
        layerName: mod.name,
        center: centerValue,
      });
    }
  }

  dispatch({
    type: 'SET_ALGORITHM_INPUT_VALUE',
    name,
    value: value as string | number,
  });
}

function SwitchBackInputs() {
  const [state, dispatch] = useEditorReducer();
  const tsai = useTsai();
  const layer = state[state.selectedLayer];

  const inputDisabled = layer.name === tabOptions[1].label && layer.syncEnabled;

  if (layer.selectedAlgorithm !== 'switchback.ghx') {
    return null;
  }

  const algorithm = layer[layer.selectedAlgorithm];

  return (
    <>
      {algorithm.inputs.map((input, index) => {
        const id = `${algorithm.type}-${input.name}-${input.tag}-input`;
        return (
          <Input
            key={id}
            id={id}
            disabled={inputDisabled}
            input={input}
            onChange={value => {
              InputDispatcher(input.name, value as string | number, dispatch, tsai, algorithm);
            }}
          />
        );
      })}
    </>
  );
}

function SplitSwitchbackInputs() {
  const [state, dispatch] = useEditorReducer();
  const tsai = useTsai();

  const layer = state[state.selectedLayer];

  const inputDisabled = layer.name === tabOptions[1].label && layer.syncEnabled;

  if (layer.selectedAlgorithm !== 'splitSwitchback.ghx') {
    return null;
  }

  const algorithm = layer[layer.selectedAlgorithm];

  const leftInputs = algorithm.inputs.filter(input => input.name.includes('Left'));
  const rightInputs = algorithm.inputs.filter(input => input.name.includes('Right'));

  return (
    <Flex direction="column" gap="size-125">
      <InputItem>
        <InputLabel quiet>Left</InputLabel>
      </InputItem>
      {leftInputs.map(input => {
        const id = `${algorithm.type}-${input.name}-${input.tag}-input`;
        return (
          <Input
            key={id}
            id={id}
            input={input}
            disabled={inputDisabled}
            onChange={value => {
              InputDispatcher(input.name, value as string | number, dispatch, tsai, algorithm);
            }}
          />
        );
      })}
      <InputItem>
        <InputLabel quiet>Right</InputLabel>
      </InputItem>
      {rightInputs.map(input => {
        const id = `${algorithm.type}-${input.name}-${input.tag}-input`;
        return (
          <Input
            key={id}
            id={id}
            input={input}
            disabled={inputDisabled}
            onChange={value => {
              InputDispatcher(input.name, value as string | number, dispatch, tsai, algorithm);
            }}
          />
        );
      })}
    </Flex>
  );
}

export function AlgorithmInputs() {
  const [state] = useEditorReducer();

  const layer = state[state.selectedLayer];

  if (!layer.selectedAlgorithm) {
    return null;
  }

  return layer.selectedAlgorithm === 'switchback.ghx' ? (
    <SwitchBackInputs />
  ) : (
    <SplitSwitchbackInputs />
  );
}
