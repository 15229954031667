// eslint-disable-next-line @nx/enforce-module-boundaries
import { colors, width as boundaryWidth } from '@nike.picc.dam/nectar';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as layers from './layers';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as artboards from './artboards';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as importCurves from './importCurves';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as exportCurves from './exportCurves';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as paths from './paths';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as xmp from './xmp';
import { AdobeCurve } from './types';
import { convertToExtendScriptPointFormat, createStringFrom } from './utils';

// TODO: improve this pattern, since its not real javascript, do we even need a regular import?
// can we somehow handle treat this as just text data?
const scripts = { ...layers, ...artboards, ...importCurves, ...exportCurves, ...paths, ...xmp };

// 	Adobe 2.834645 points = 1 millimeter
export const ptToMm = 0.352778;
export const mmToPt = 2.834645;

// Allows us to ensure that whenever someone tries to instantiate
// a Tsai client, they've passed it the window with CSInterface
// already attached
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CSInterface: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cep: any;
  }
}

export interface ITsai {
  createLayer(name: string): void;
  removeLayer(name: string, forceRemove: boolean): void;
  selectLayer(name: string): void;
  clearLayer(name: string): void;
  getArtboards(): Promise<unknown>;
  getActiveArtboardInfo(): Promise<unknown>;
  resizeArtboard(width: number, height: number): Promise<unknown>;
  createNewPolylineOn(
    layer: string,
    points: AdobeCurve,
    closed: boolean,
    color: colors,
    weight: boundaryWidth,
    fill: string
  ): Promise<unknown>;
  createNewCircleOn(layer: string, x: number, y: number, r: number): Promise<unknown>;
  lockLayer(layerName: string): Promise<unknown>;
  unlockLayer(layerName: string): Promise<unknown>;
  getLayerNames(): Promise<string[]>;
  validateLayer(layerName: string): Promise<string>;
  getInfoFor(layerName: string): Promise<string>;
  getPaths(layerName: string): Promise<string>;
  getBoundingBoxes(layerName: string): Promise<string>;
  simplifyPaths(layerName: string): Promise<string>;
  setPathsColor(layerName: string, color: colors): Promise<unknown>;
  setPathsWidth(layerName: string, width: boundaryWidth): Promise<unknown>;
  openExportDialog(action: string): any;
  exportTxtFile(path: string, data: string): void;
  setWindowTitle(title: string): void;
  readXmp(key: string): Promise<string>;
  writeXmp(key: string, data: string): void;
  getSelection(): Promise<string>;
}

// The caller is responsible for importing CSInterface in their extension
// Tsai will then manage the integration with CEP
export class Tsai implements ITsai {
  // CSInterface always makes calls to a __adobe_cep__ property on window
  window: Window;

  csInterface: any;

  cep: any;

  constructor(window: Window) {
    this.window = window;
    this.csInterface = new window.CSInterface();
    this.cep = window.cep;
    if (this.csInterface.hostEnvironment === null) {
      throw new Error(
        'Could not determine the hostEnvironment, is the web app running within Adobe?'
      );
    }
  }

  async writeXmp(key: string, data: string) {
    const extendScriptString = createStringFrom(scripts.writeXMP, key, data);
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  async readXmp(key: string): Promise<any> {
    const extendScriptString = createStringFrom(scripts.readXMP, key);
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  createLayer(name: string): void {
    // TODO: can we abstract this pattern of create string and then evalScript?
    const extendScriptString = createStringFrom(scripts.layers.addLayer, name);
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
  }

  removeLayer(layerName: string, forceRemove: boolean) {
    const extendScriptString = createStringFrom(scripts.layers.removeLayer, layerName, forceRemove);
    const evalScriptPromise = new Promise<string[]>(resolve => {
      this.csInterface.evalScript(extendScriptString, (value: string) => {
        resolve(value.split(','));
      });
    });
    return evalScriptPromise;
  }

  selectLayer(layerName: string) {
    const extendScriptString = createStringFrom(scripts.layers.selectLayer, layerName);
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  clearLayer(layerName: string) {
    const extendScriptString = createStringFrom(scripts.layers.clearLayer, layerName);
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  async getArtboards(): Promise<unknown> {
    const extendScriptString = createStringFrom(scripts.artboards.getArtboards);
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  async getActiveArtboardInfo(): Promise<unknown> {
    const extendScriptString = createStringFrom(scripts.artboards.getArtboardInfo);
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  async resizeArtboard(width: number, height: number): Promise<unknown> {
    const extendScriptString = createStringFrom(
      scripts.artboards.setArtboardRect,
      0,
      0,
      width,
      height
    );
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  async createNewCircleOn(
    layer: string,
    centerX: number,
    centerY: number,
    radius: number
  ): Promise<unknown> {
    const extendScriptString = createStringFrom(
      scripts.importCurves.addCircle,
      layer,
      centerX,
      centerY,
      radius
    );
    const evalScriptPromise = new Promise((resolve, reject) => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  async createNewPolylineOn(
    layer: string,
    points: AdobeCurve,
    closed: boolean,
    color: colors,
    weight: boundaryWidth,
    fill: string
  ): Promise<unknown> {
    const extendScriptString = createStringFrom(
      scripts.importCurves.addClosedPolyline,
      layer,
      convertToExtendScriptPointFormat(points),
      closed,
      color,
      weight,
      fill
    );
    const evalScriptPromise = new Promise((resolve, reject) => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });
    return evalScriptPromise;
  }

  async lockLayer(layerName: string) {
    const extendScriptString = createStringFrom(scripts.layers.setLayerLock, layerName, true);
    const evalScriptPromise = new Promise<string[]>(resolve => {
      this.csInterface.evalScript(extendScriptString, (value: string) => {
        resolve(value.split(','));
      });
    });
    return evalScriptPromise;
  }

  async unlockLayer(layerName: string) {
    const extendScriptString = createStringFrom(scripts.layers.setLayerLock, layerName, false);
    const evalScriptPromise = new Promise<string[]>(resolve => {
      this.csInterface.evalScript(extendScriptString, (value: string) => {
        resolve(value.split(','));
      });
    });
    return evalScriptPromise;
  }

  async getLayerNames() {
    const extendScriptString = createStringFrom(scripts.layers.getLayerNames);
    const evalScriptPromise = new Promise<string[]>(resolve => {
      this.csInterface.evalScript(extendScriptString, (value: string) => {
        resolve(value.split(','));
      });
    });
    return evalScriptPromise;
  }

  async validateLayer(layer: string) {
    const extendScriptString = createStringFrom(scripts.layers.validateLayer, layer);
    const evalScriptPromise = new Promise<string>(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });

    return evalScriptPromise;
  }

  async getInfoFor(layerName: string) {
    const extendScriptString = createStringFrom(scripts.layers.getLayerInfo, layerName);
    const evalScriptPromise = new Promise<string>(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });

    return evalScriptPromise;
  }

  async getPaths(layer: string) {
    const extendScriptString = createStringFrom(scripts.exportCurves.getAIPaths, layer);
    const evalScriptPromise = new Promise<string>(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });

    return evalScriptPromise;
  }

  async getBoundingBoxes(layer: string) {
    const extendScriptString = createStringFrom(scripts.exportCurves.getBoundingBoxes, layer);
    const evalScriptPromise = new Promise<string>(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });

    return evalScriptPromise;
  }

  async simplifyPaths(layer: string) {
    const extendScriptString = createStringFrom(scripts.layers.simplifyPaths, layer);
    const evalScriptPromise = new Promise<string>(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });

    return evalScriptPromise;
  }

  async getSelection() {
    const extendScriptString = createStringFrom(scripts.paths.getSelection);
    const evalScriptPromise = new Promise<string>(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });

    return evalScriptPromise;
  }

  async setPathsColor(layer: string, color: colors) {
    const [red, green, blue] = color.split(', ');
    const extendScriptString = createStringFrom(
      scripts.layers.setPathsColor,
      layer,
      red,
      green,
      blue
    );
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });

    return evalScriptPromise;
  }

  async setPathsWidth(layer: string, width: boundaryWidth) {
    const extendScriptString = createStringFrom(
      scripts.layers.setPathsWidth,
      layer,
      width * mmToPt
    );
    const evalScriptPromise = new Promise(resolve => {
      this.csInterface.evalScript(extendScriptString, resolve);
    });

    return evalScriptPromise;
  }

  // Allows the client to open an export dialog to save data from the extension
  openExportDialog(action: string) {
    return this.cep.fs.showSaveDialogEx(action, null, ['json']);
  }

  exportTxtFile(path: string, data: string) {
    this.cep.fs.writeFile(path, data);
  }

  setWindowTitle(title: string) {
    this.csInterface.setWindowTitle(title);
  }
}
