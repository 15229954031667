import { match } from 'ts-pattern';

// Interfaces for FileTransform
export interface UpstreamTextInputSelection {
  kind: 'upstreamTextInputSelection';
  stepId: string;
  fieldId: string;
  fieldName: string;
}

export interface Divider {
  kind: 'divider';
  divider: string;
}

export interface CustomText {
  kind: 'customText';
  text: string;
}

export interface CurrentDate {
  kind: 'currentDate';
}

export interface CurrentTime {
  kind: 'currentTime';
}

export interface FileName {
  kind: 'fileName';
}

export interface FileExtension {
  kind: 'fileExtension';
}

export interface User {
  kind: 'user';
}

export interface UUID {
  kind: 'uuid';
}

export type FileNameTransform =
  | UpstreamTextInputSelection
  | Divider
  | CustomText
  | CurrentDate
  | CurrentTime
  | FileName
  | FileExtension
  | User
  | UUID;

export const fileNameTransformToDisplayText = (transform: FileNameTransform) => {
  const text = match(transform)
    .with({ kind: 'currentDate' }, () => `{ ${transform.kind} }`)
    .with({ kind: 'currentTime' }, () => `{ ${transform.kind} }`)
    .with({ kind: 'user' }, () => `{ ${transform.kind} }`)
    .with({ kind: 'uuid' }, () => `{ ${transform.kind} }`)
    .with({ kind: 'divider' }, filtered => filtered.divider)
    .with({ kind: 'upstreamTextInputSelection' }, filtered => `{ ${filtered.fieldName} }`)
    .with({ kind: 'customText' }, filtered => filtered.text)
    .with({ kind: 'fileName' }, () => `{ ${transform.kind} }`)
    .with({ kind: 'fileExtension' }, () => `{ ${transform.kind} }`)
    .exhaustive();

  return text;
};
