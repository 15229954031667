/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable-next-line react/destructuring-assignment */
import { View, Image, Text, Flex } from '@adobe/react-spectrum';
import React, { useEffect } from 'react';
import { AssetSearchResponse } from '@nike.picc.dam/aurora';
import arrowGrayPng from '../../assets/midsole/arrow-gray.png';
import arrowGreenPng from '../../assets/midsole/arrow-green.png';

type MidsoleProps = {
  uuId: string;
  name: string;
  handleMidsoleSelection: (value: string) => void;
  selectedMidsoleId: string;
};
export function MidsoleCard({
  uuId,
  name,
  handleMidsoleSelection,
  selectedMidsoleId,
}: MidsoleProps) {
  return (
    <View>
      <Flex direction="column" UNSAFE_style={{ flexGrow: 1 }}>
        <div onClick={() => handleMidsoleSelection(uuId)}>
          <View
            borderWidth="thin"
            borderColor={selectedMidsoleId === uuId ? 'celery-400' : 'gray-600'}
            backgroundColor="gray-400"
            width="size-1600"
            height="size-1600"
            UNSAFE_style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              borderRadius: 10,
            }}
          >
            <Image
              src="../../assets/midsole/midsole-blank.png"
              alt={name}
              width="size-525"
              height="size-1200"
            />
          </View>
        </div>
        <Flex
          marginTop="20px"
          marginBottom="20px"
          direction="row"
          flexGrow={1}
          justifyContent="space-between"
        >
          <Text
            UNSAFE_style={{
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            {name}
          </Text>
          <Image
            src={selectedMidsoleId === uuId ? arrowGreenPng : arrowGrayPng}
            width="size-160"
            alt="Arrow to indicate midsole selection"
            height="size-160"
            alignSelf="center"
          />
        </Flex>
      </Flex>
    </View>
  );
}

export interface MidsoleGridProps {
  selectedMidsoleId: string;
  setSelectedMidsole: React.Dispatch<React.SetStateAction<string>>;
  midsoles: AssetSearchResponse['results'];
}

export function MidsoleGrid({ selectedMidsoleId, setSelectedMidsole, midsoles }: MidsoleGridProps) {
  const [midsoleCardItems, setMidsoleCardItems] = React.useState<any | null>(null);

  const handleMidsoleSelection = (midsoleId: string) => {
    setSelectedMidsole(midsoleId);
  };

  useEffect(() => {
    const midsoleCardViews = midsoles.map(midsoleItem => (
      <MidsoleCard
        key={midsoleItem.assetId}
        uuId={midsoleItem.assetId}
        name={midsoleItem.name}
        handleMidsoleSelection={handleMidsoleSelection}
        selectedMidsoleId={selectedMidsoleId}
      />
    ));

    setMidsoleCardItems(midsoleCardViews);
  }, [selectedMidsoleId]);

  return (
    <View minWidth="size-1600">
      <Flex
        justifyContent="space-around"
        marginBottom="size-350"
        margin="size-200"
        direction="row"
        gap="size-100"
        wrap
      >
        {midsoleCardItems}
      </Flex>
    </View>
  );
}

export default MidsoleGrid;
