/* eslint-disable */
// These are scripts that will be run in AI (it's JavaScript but it's not run by a web browser)
// For details see: https://ai-scripting.docsforadobe.dev/
export const paths = {
  getSelection() {
    var doc = app.activeDocument;
    var selection = app.activeDocument.selection;

    var json = '{"count":0,"pathItems":[]}';
    if (selection) {
      // Generate JSON for page items in selection
      var itemInfo = '[';
      for (var index = 0; index < selection.length; index++) {
        var item = selection[index];
        if (item.typename == 'PathItem') {
          var json = '{';
          json += '"closed":' + item.closed + ',';
          json += '"layer":"' + item.layer.name + '",';
          json += '"uuid":"' + item.uuid + '"';
          json += '}';

          if (index != selection.length - 1) {
            json += ',';
          }

          itemInfo += json;
        }
      }
      itemInfo += ']';

      json = '{';
      json += '"count":' + selection.length + ',';
      json += '"pathItems":' + itemInfo;
      json += '}';
    }

    return json;
  },
};
