import { z } from 'zod';
import { AssetCreate, AssetCreateVersion, AssetGetResponse } from '../../base/types/asset';

const SUB_CLASS = 'digitaladditivemanufacturing';
const RUNTIME = 'grasshopper';

// required asset-level metadata
const assetMetadata = z
  .object({
    subClass: z.literal(SUB_CLASS).default(SUB_CLASS),
    runtime: z.string().default(RUNTIME),
    methodOfMake: z.string().array().optional(),
    description: z.string().optional(),
  })
  .default({});

// required version-level metadata
const versionMetadata = z.object({
  description: z.string().optional(),
  versionNotes: z.string().optional(),
});

// schemas
export const ComputationDefinitionCreate = AssetCreate.extend({
  assetMetadata,
  versionMetadata,
});
export const ComputationDefinitionCreateVersion = AssetCreateVersion.extend({
  assetMetadata,
  versionMetadata,
});
export const ComputationDefinitionGetResponse = AssetGetResponse.extend({
  assetMetadata,
  versionMetadata,
});

// types

export type ComputationDefinitionCreate = z.infer<typeof ComputationDefinitionCreate>;
export type ComputationDefinitionCreateVersion = z.infer<typeof ComputationDefinitionCreateVersion>;
export type ComputationDefinitionGetResponse = z.infer<typeof ComputationDefinitionGetResponse>;
