import React from 'react';
import { Tsai } from '@nike.picc.dam/ts-ai';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Provider as ThemeProvider, defaultTheme } from '@adobe/react-spectrum';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { GrasshopperClient } from '@nike.picc.dam/grasshopper-client';
import { queryClient } from '../lib/react-query';
import { TsaiService } from '../lib/tsai';
import { TsaiProvider } from './tsai-provider';
import { environment } from '../environments/environment';
import { GrasshopperClientProvider } from './grasshopper-provider';
import { GrasshopperClientService } from '../lib/grasshopper';

const tsaiService = new TsaiService(new Tsai(window));

const grasshopperClientService = new GrasshopperClientService(queryClient, new GrasshopperClient());

export const oktaAuth = new OktaAuth({
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid'],
  pkce: true,
  tokenManager: {
    autoRenew: true,
  },
});

// separate <BrowserRouter /> to allow us to use `useNavigate` inside of <AppProvider />
function AppProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <QueryClientProvider client={queryClient}>
      {environment.local ? <ReactQueryDevtools /> : null}
      <TsaiProvider service={tsaiService}>
        <GrasshopperClientProvider service={grasshopperClientService}>
          <ThemeProvider theme={defaultTheme}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              {children}
            </Security>
          </ThemeProvider>
        </GrasshopperClientProvider>
      </TsaiProvider>
    </QueryClientProvider>
  );
}

function AppProviderWithRouter({ children }: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <AppProvider>{children}</AppProvider>
    </BrowserRouter>
  );
}

export {
  // Rename to not expose separation.
  AppProviderWithRouter as AppProvider,
};
