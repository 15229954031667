/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Heading,
  Button,
  Text,
  Flex,
  View,
  Image,
  Divider,
  ProgressCircle,
} from '@adobe/react-spectrum';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { AssetSearchResponse, Aurora, AuroraBaseUrl } from '@nike.picc.dam/aurora';
import { AdobeCurve } from '@nike.picc.dam/ts-ai';
import NavigationHeader from '../nagivation-header/navigation-header';
import outsoleImage from '../../assets/outsole-select.png';
import horizontalCardPng from '../../assets/horizontal-card.png';
import { useTsai } from '../../providers/tsai-provider';
import MidsoleGrid from './midsole-grid';
import { environment } from '../../environments/environment';
// import defaultBoundary from '../../assets/midsole-pattern.json';

export function OutsoleImage() {
  return <img src={outsoleImage} alt="Outsole" />;
}

// TODO: rebuild this with proper components not by hand, do we really need a picture with text?
export function HorizontalCard() {
  return <Image src={horizontalCardPng} alt="Horizontal Card UNFINISHED" margin="size-100" />;
}

const adobeToRhino = async (data: AdobeCurve[]) => {
  const rhino = await (window as any).rhino3dm();
  const aiPaths = data;

  // Adjust position to land in the right place in the Rhino document
  // TODO: Don't hardcode these values (also see sendVerticesToAI)
  const ptToMm = 0.352778;
  const mmToPt = 2.834645;
  const offsetX = -118 * mmToPt;
  const offsetY = 336 * mmToPt;

  // Generate a series of nurb curves and join
  const rhinoCurves = [];
  for (let i = 0; i < aiPaths.length; i += 1) {
    const path = aiPaths[i];

    // Break path into a series of curves to make a final polycurve
    const polyCurve = new rhino.PolyCurve();
    for (let j = 0; j < path.points.length - 1; j += 1) {
      const p0 = path.points[j].anchor;
      const p1 = path.points[j].rightDirection;
      const p2 = path.points[j + 1].leftDirection;
      const p3 = path.points[j + 1].anchor;

      const points = new rhino.Point3dList();
      points.add(p0.x + offsetX, p0.y + offsetY, 0);
      points.add(p1.x + offsetX, p1.y + offsetY, 0);
      points.add(p2.x + offsetX, p2.y + offsetY, 0);
      points.add(p3.x + offsetX, p3.y + offsetY, 0);

      const nurbCurve = rhino.NurbsCurve.create(false, 3, points);
      nurbCurve.makePiecewiseBezier(true);
      polyCurve.append(nurbCurve);
    }

    // Add last segment (if closed)
    if (path.closed) {
      const p0 = path.points[path.points.length - 1].anchor;
      const p1 = path.points[path.points.length - 1].rightDirection;
      const p2 = path.points[0].leftDirection;
      const p3 = path.points[0].anchor;

      const points = new rhino.Point3dList();
      points.add(p0.x + offsetX, p0.y + offsetY, 0);
      points.add(p1.x + offsetX, p1.y + offsetY, 0);
      points.add(p2.x + offsetX, p2.y + offsetY, 0);
      points.add(p3.x + offsetX, p3.y + offsetY, 0);

      const nurbCurve = rhino.NurbsCurve.create(false, 3, points);
      nurbCurve.makePiecewiseBezier(true);
      polyCurve.append(nurbCurve);
    }

    polyCurve.scale(ptToMm);
    rhinoCurves.push(polyCurve);
  }

  return JSON.stringify(rhinoCurves[0].encode());
};

async function getMidsoleOutline(
  midsoleId: string,
  midsoles: AssetSearchResponse,
  token: string
): Promise<any> {
  const midsole = midsoles.results.find(i => i.assetId === midsoleId);
  const midsoleArtifact = midsole?.version.artifactReferences.find(
    artifact => artifact.fileType === 'JSON'
  );
  if (token !== undefined && midsoleArtifact !== undefined) {
    const response = await axios.get(midsoleArtifact?.contentUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data as AdobeCurve;
  }
  return new Error('Incorrect midsole format.');
}

export function MidsolePage() {
  const [selectedMidsoleId, setSelectedMidsoleId] = useState<string>('');
  const [midsoles, setMidsoles] = useState<AssetSearchResponse | undefined>(undefined);
  const from = 0;
  const size = 30;

  const navigate = useNavigate();
  const tsai = useTsai();

  const aurora = new Aurora({
    baseUrl: !environment.production ? AuroraBaseUrl.TEST : AuroraBaseUrl.PRODUCTION,
  });
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const fetchMidsoles = async () =>
      aurora.Midsole.search({ from, size, active: true }, `${oktaAuth.getAccessToken()}`);

    fetchMidsoles()
      .then(x => setMidsoles(x))
      .catch(e => {
        console.error(e);
      });
  }, [from, size]);

  return (
    <View minHeight="100vh" paddingX="size-250" backgroundColor="gray-100">
      <Flex direction="column">
        <NavigationHeader />
        <Heading level={1}>
          <div style={{ lineHeight: '28px' }}>
            Additive
            <br />
            Outsoles
          </div>
        </Heading>
        <Flex marginTop="size-250" marginBottom="size-250">
          <Text flexGrow={1} UNSAFE_style={{ fontWeight: 400, fontSize: 14 }}>
            Choose a midsole and click “Create” below:
          </Text>
        </Flex>
        {midsoles !== undefined ? (
          <MidsoleGrid
            selectedMidsoleId={selectedMidsoleId}
            setSelectedMidsole={setSelectedMidsoleId}
            midsoles={midsoles.results}
          />
        ) : (
          <Flex justifyContent="center" marginBottom="1em">
            <ProgressCircle size="L" aria-label="Loading…" isIndeterminate />
          </Flex>
        )}
        <Divider size="M" />
        <Flex justifyContent="end" direction="row" gap="size-100" marginTop="size-250">
          <Button
            href="https://nike.box.com/s/rmre06clnok1fhfc29nly3z9pedewous"
            target="_blank"
            variant="primary"
            onPress={() => {
              navigate('/');
            }}
          >
            Cancel
          </Button>

          <Button
            href="https://nike.box.com/s/rmre06clnok1fhfc29nly3z9pedewous"
            target="_blank"
            variant="cta"
            onPress={async () => {
              if (midsoles !== undefined) {
                tsai.writeXmp('midsoleId', selectedMidsoleId);
                const boundary = await getMidsoleOutline(
                  selectedMidsoleId,
                  midsoles,
                  `${oktaAuth.getAccessToken()}`
                );
                tsai.setupArtboard(boundary);
                const data = await adobeToRhino([boundary]);
                tsai.writeXmp('midsoleBoundary', data);
              }
              navigate('/nectar');
            }}
            isDisabled={selectedMidsoleId === ''}
          >
            Create
          </Button>
        </Flex>
      </Flex>
    </View>
  );
}

export default MidsolePage;
