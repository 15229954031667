import { createContext, useContext } from 'react';
import { Tsai } from '@nike.picc.dam/ts-ai';
import { ITsaiService, TsaiService } from '../lib/tsai';

export const TsaiContext = createContext<ITsaiService | null>(null);

export function TsaiProvider({
  children,
  service = new TsaiService(new Tsai(window)),
}: {
  children: React.ReactNode;
  service: ITsaiService;
}) {
  return <TsaiContext.Provider value={service}>{children}</TsaiContext.Provider>;
}

export function useTsai() {
  const tsai = useContext(TsaiContext);

  if (!tsai) {
    throw new Error('`useTsai` be used within a `TsaiProvider`');
  }

  return tsai;
}
