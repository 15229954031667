import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
    },
  },
});

export const testQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // prevent "Jest did not exit one second after the test run completed" errors
      cacheTime: Infinity,
    },
  },
  // turn off network error logging for tests
  logger: {
    log: console.log,
    warn: console.warn,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    error: () => {},
  },
});
