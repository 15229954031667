import {
  Form,
  Divider,
  DialogTrigger,
  ButtonGroup,
  Button,
  AlertDialog,
  Switch,
  Text,
  Flex,
} from '@adobe/react-spectrum';
import { ComposureClient } from '@nike.picc.dam/composure-sdk';
import { useOktaAuth } from '@okta/okta-react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { environment } from '../../environments/environment';
import { useTsai } from '../../providers/tsai-provider';
import { CollapsibleSection } from './collapsible-section';
import { tabOptions } from './constants';
import { useEditorReducer } from './editor-reducer';
import { MaterialInputs } from './material-inputs';
import { MidsoleInputs } from './midsole-inputs';

export function EditorForm() {
  const [state, dispatch] = useEditorReducer();
  const tsai = useTsai();
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState<boolean>(false);
  const { oktaAuth } = useOktaAuth();

  const composureSdk = new ComposureClient(
    `${oktaAuth.getAccessToken()}`,
    environment.production ? 'prod' : 'test'
  );

  const exportDesignPathsMutation = useMutation(
    ['exportDesignPathsMutation'],
    (layerNames: Array<string>) => tsai.exportDesignPaths(layerNames),
    {
      onError: () => setIsAlertDialogOpen(true),
    }
  );

  const layer = state[state.selectedLayer];

  return (
    <Form
      onSubmit={async e => {
        const topLayerStatus = state.topLayer.status;
        const baseLayerStatus = state.baseLayer.status;
        const exportedLayers = [];
        let sel0Points;
        let sel1Points;

        // Unable to test if this payload is correct due to hardcoded response
        let jsonPayload = '{';

        if (baseLayerStatus === 'enabled') {
          exportedLayers.push(tabOptions[0].label);
        }
        if (topLayerStatus === 'enabled') {
          exportedLayers.push(tabOptions[1].label);
        }

        e.preventDefault();
        await tsai.validateLayers(exportedLayers);

        const pathsToExport = await tsai.getPaths(exportedLayers);

        // Adobe 2.834645 points = 1 millimeter
        // These should eventually live somewhere else if this app becomes real
        const mmToPt = 2.834645;

        const offsetX = -118 * mmToPt;
        const offsetY = 336 * mmToPt;

        // Base Layer Path [{x: number, y: number: z: number}]
        if (baseLayerStatus === 'enabled') {
          sel0Points = pathsToExport[0][0].path.map((x: any) => {
            const rhinoMovedPoints = { X: x.x + offsetX, Y: x.y + offsetY, Z: x.z };
            return JSON.stringify(rhinoMovedPoints);
          });
          jsonPayload += `Sel0_Pts: [${sel0Points}],`;
        }

        // Top Layer Path
        if (topLayerStatus === 'enabled') {
          sel1Points = pathsToExport[1][0].path.map((x: any) => {
            const rhinoMovedPoints = { X: x.x + offsetX, Y: x.y + offsetY, Z: x.z };
            return JSON.stringify(rhinoMovedPoints);
          });
          jsonPayload += `Sel1_Pts: [${sel1Points}],`;
        }

        await tsai.readXmp('midsoleId').then(async id => {
          jsonPayload += `CAD_UID: "${id}",`;
        });

        jsonPayload += '}';

        const result = await composureSdk.solve(
          'a0af0760-8ae0-4c66-8858-748f9c5a5a28',
          jsonPayload
        );

        if (result.ok) {
          // eslint-disable-next-line dot-notation
          const parsedJson = `${result.val['json'][0].replace(/\\/g, '')}`;
          try {
            const filePath = tsai.openExportDialog('Export Nectar Design for Print');
            // TODO: standardize format of data going in an out of AI
            // Saving as txt file for now, could easily move to JSON as payload grows more complex
            tsai.exportTxtFile(filePath.data, parsedJson);
          } catch (err) {
            console.error(err);
          }
        }
      }}
      isDisabled={layer.status === 'disabled'}
    >
      <CollapsibleSection
        title="Material"
        isExpanded={layer.isMaterialSectionExpanded}
        onToggle={() => {
          dispatch({ type: 'TOGGLE_SECTION_IS_EXPANDED', section: 'material' });
        }}
      >
        <MaterialInputs />
      </CollapsibleSection>
      <div>
        <Divider size="S" />
        <CollapsibleSection
          title="Midsole Zone"
          isExpanded={layer.isMidsoleSectionExpanded}
          onToggle={() => {
            dispatch({ type: 'TOGGLE_SECTION_IS_EXPANDED', section: 'midsole' });
          }}
        >
          <Flex direction="column">
            {layer.name === tabOptions[0].label && (
              <Switch
                isEmphasized
                isSelected={layer.syncEnabled}
                isDisabled={state.topLayer.freehandEnabled || state.baseLayer.freehandEnabled}
                onChange={async e => {
                  dispatch({ type: 'TOGGLE_LAYER_SYNC' });
                }}
              >
                Sync Top Layer
              </Switch>
            )}
            <Switch
              isDisabled={layer.syncEnabled}
              isEmphasized
              isSelected={layer.freehandEnabled}
              onChange={async e => {
                dispatch({ type: 'TOGGLE_FREEHAND' });
                if (!layer.freehandEnabled === true) {
                  await tsai.unlockLayer(layer.name);
                } else {
                  await tsai.lockLayer(layer.name);
                }
              }}
            >
              Freehand Edit
            </Switch>
          </Flex>
          <br />
          <Flex>
            {layer.freehandEnabled && (
              <Text>
                Computational design tools are not available when freehand editing. Turning off
                Freehand Edit and going back to computational design tools will result in a loss of
                freehand work.
              </Text>
            )}
            {!layer.freehandEnabled && <MidsoleInputs />}
          </Flex>
        </CollapsibleSection>
      </div>
      <DialogTrigger isOpen={isAlertDialogOpen}>
        <ButtonGroup align="end" marginY="size-250">
          <Button variant="cta" type="submit">
            Print...
          </Button>
        </ButtonGroup>
        <AlertDialog
          title="Cannot print current design"
          variant="error"
          primaryActionLabel="Close"
          onPrimaryAction={() => {
            setIsAlertDialogOpen(false);
          }}
        >
          You must have one continuous path inside the boundaries of the midsole. Please ensure the
          design is a single, continuous path, then print again.
        </AlertDialog>
      </DialogTrigger>
    </Form>
  );
}
