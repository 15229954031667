import { colors, width } from '@nike.picc.dam/nectar';

export const tabOptions = [
  {
    id: 'baseLayer',
    label: 'Base Layer',
  },
  {
    id: 'topLayer',
    label: 'Top Layer',
  },
] as const;

export const materialColorOptions = [
  {
    id: 'black',
    label: 'Black',
    value: colors.BLACK,
  },

  {
    id: 'brightCrimson',
    label: 'Bright Crimson',
    value: colors.BRIGHT_CRIMSON,
  },
  {
    id: 'greenGlow',
    label: 'Green Glow',
    value: colors.GREEN_GLOW,
  },
  {
    id: 'gumLightBrown',
    label: 'Gum Light Brown',
    value: colors.GUM_LIGHT_BROWN,
  },
  {
    id: 'signalBlue',
    label: 'Signal Blue',
    value: colors.SIGNAL_BLUE,
  },
  {
    id: 'clear',
    label: 'Clear',
    value: colors.CLEAR,
  },
] as const;

export const materialSizeOptions = Object.values(width)
  .filter((value): value is number => typeof value === 'number')
  .map(value => ({
    value,
  }));

// this does not appear to be used
export const midsoleZoneBaseOptions = [
  {
    label: 'Switchback',
    value: 'switchback',
  },
  {
    label: 'Split Switchback',
    value: 'splitSwitchback',
  },
  {
    label: 'None',
    value: 'none',
  },
] as const;
