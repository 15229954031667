import { ReactNode } from 'react';
import { Grid, Item, NumberField, Picker, Slider, Text } from '@adobe/react-spectrum';
import { AlgorithmInput } from '@nike.picc.dam/grasshopper-client';

type InputItemProps = {
  children: ReactNode;
};

export function InputItem({ children }: InputItemProps) {
  return (
    <Grid columns={['size-1000', '1fr', 'auto']} alignItems="center" columnGap="size-175">
      {children}
    </Grid>
  );
}

type InputLabelProps = {
  // eslint-disable-next-line react/require-default-props
  id?: string;
  // eslint-disable-next-line react/require-default-props
  quiet?: boolean;
  children: ReactNode;
};

// Needed for input label grid alignment as spectrum inputs have wrappers that
// don't let a grid control label/input placement
export function InputLabel({ id, quiet = false, children }: InputLabelProps) {
  return (
    <Text
      id={id}
      marginTop={quiet ? 'size-200' : undefined}
      UNSAFE_style={{
        color: quiet
          ? 'var(--spectrum-alias-placeholder-text-color)'
          : 'var(--spectrum-fieldlabel-text-color,var(--spectrum-alias-label-text-color))',
        fontSize:
          'var(--spectrum-fieldlabel-text-size,var(--spectrum-global-dimension-font-size-75))',
      }}
    >
      {children}
    </Text>
  );
}

type InputProps = {
  id: string;
  input: AlgorithmInput;
  disabled: boolean;
  onChange: (value: unknown) => void;
};

export function Input({ id, input, disabled, onChange }: InputProps) {
  if (input.tag === 'Dropdown') {
    const options = input.options.map(option => ({
      value: option,
    }));
    return (
      <InputItem>
        <InputLabel id={id}>{input.name}</InputLabel>
        <Picker
          items={options}
          selectedKey={input.value}
          isDisabled={disabled}
          onSelectionChange={value => onChange(value as string)}
          aria-labelledby={id}
        >
          {item => (
            <Item key={item.value} textValue={item.value}>
              {item.value.substring(0, 14)}
            </Item>
          )}
        </Picker>
      </InputItem>
    );
  }

  if (input.tag === 'Slider') {
    return (
      <InputItem>
        <InputLabel id={id}>{input.name}</InputLabel>
        <Slider
          value={input.value}
          minValue={input.min}
          maxValue={input.max}
          onChange={onChange}
          isDisabled={disabled}
          showValueLabel={false}
          step={input.step}
          aria-labelledby={id}
          width="100%"
        />
        <NumberField
          value={input.value}
          minValue={input.min}
          maxValue={input.max}
          onChange={onChange}
          isDisabled={disabled}
          step={input.step}
          hideStepper
          aria-labelledby={id}
          maxWidth="size-800"
        />
      </InputItem>
    );
  }

  return <div />;
}
