/* eslint-disable */
// These are scripts that will be run in AI (it's JavaScript but it's not run by a web browser)
// For details see: https://ai-scripting.docsforadobe.dev/
export const layers = {
  fancyAlert(message) {
    alert(message, 'Access Denied', true);
  },

  addLayer(targetLayer) {
    var doc = app.activeDocument;

    // Check if we already have this layer...
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }
    if (!layer) {
      layer = doc.layers.add();
      layer.name = targetLayer;
    }
  },

  clearLayer(targetLayer) {
    var doc = app.activeDocument;

    // Find results layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      layer.pathItems.removeAll();
    }
  },

  selectLayer(targetLayer) {
    var doc = app.activeDocument;

    // Find results layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      doc.activeLayer = layer;
    }
  },

  getLayerInfo(targetLayer) {
    var doc = app.activeDocument;
    var info = '{}';

    // Find desired layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      // Generate JSON for page items in layer
      var itemInfo = '[';
      for (var index = 0; index < layer.pageItems.length; index++) {
        var pageItem = layer.pageItems[index];
        var json = '{';
        json += '"height":' + pageItem.height + ',';
        json += '"left":' + pageItem.left + ',';
        json += '"top":' + pageItem.top + ',';
        json += '"uRL":"' + pageItem.uRL + '",';
        json += '"uuid":"' + pageItem.uuid + '",';
        json += '"width":' + pageItem.width;
        json += '}';

        if (index != layer.pageItems.length - 1) {
          json += ',';
        }

        itemInfo += json;
      }
      itemInfo += ']';

      // Generate JSON for layer
      info = '{';
      info += '"name":"' + layer.name + '",';
      info += '"compoundPathItems":' + layer.compoundPathItems.length + ',';
      info += '"dimPlacedImages":' + layer.dimPlacedImages + ',';
      info += '"graphItems":' + layer.graphItems.length + ',';
      info += '"groupItems":' + layer.groupItems.length + ',';
      info += '"items":' + itemInfo + ',';
      info += '"layers":' + layer.layers.length + ',';
      info += '"locked":' + layer.locked + ',';
      info += '"meshItems":' + layer.meshItems.length + ',';
      info += '"nonNativeItems":' + layer.nonNativeItems.length + ',';
      info += '"pageItems":' + layer.pageItems.length + ',';
      info += '"pathItems":' + layer.pathItems.length + ',';
      info += '"placedItems":' + layer.placedItems.length + ',';
      info += '"pluginItems":' + layer.pluginItems.length + ',';
      info += '"rasterItems":' + layer.rasterItems.length + ',';
      info += '"symbolItems":' + layer.symbolItems.length + ',';
      info += '"textFrames":' + layer.textFrames.length + ',';
      info += '"visible":' + layer.visible;
      info += '}';
    }

    return info;
  },

  getLayerNames() {
    var doc = app.activeDocument;
    var layers = [];

    for (var index = 0; index < doc.layers.length; index++) {
      layers.push(doc.layers[index].name);
    }

    return layers;
  },

  validateLayer(designLayerName, boundaryLayerName) {
    var doc = app.activeDocument;

    if (!boundaryLayerName) {
      boundaryLayerName = 'Midsole Zone';
    }

    // Find the boundary layer
    var boundaryLayer = doc.layers.getByName(boundaryLayerName);

    if (!boundaryLayer) {
      return '{"error": "no boundary layer found"}';
    }

    if (boundaryLayer.pathItems.length != 1) {
      return '{"error": "boundary layer must contain a single continuous path"}';
    }

    if (!boundaryLayer.pathItems[0].closed) {
      return '{"error": "boundary layer must be a closed path"}';
    }

    // Find the design layer
    var designLayer = doc.layers.getByName(designLayerName);

    if (!designLayer) {
      return '{"error": "target layer not found"}';
    }

    if (designLayer.pathItems.length != 1) {
      return '{"error": "target layer must contain a single continuous path"}';
    }

    // Delete any previous validation layers
    for (var index = 0; index < doc.layers.length; index++) {
      var layer = doc.layers[index];
      if (layer.name === 'Out of Bounds Sections') {
        layer.remove();
      }
    }

    // Create a layer to do all of the validation work
    var validationLayer = doc.layers.add();
    validationLayer.name = 'Out of Bounds Sections';

    // Ensure neither the design or boundary layer are locked in order to
    // duplicate and move them
    var isDesignLayerLocked = designLayer.locked;
    var isBoundaryLayerLocked = boundaryLayer.locked;

    designLayer.locked = false;
    boundaryLayer.locked = false;

    // The design layer MUST have a higher "zOrderPosition" than the
    // boundaryLayer for the "Live Pathfinder Minus Back" command to work how
    // we want it to
    designLayer.pathItems[0].duplicate().move(validationLayer, ElementPlacement.PLACEATBEGINNING);
    boundaryLayer.pathItems[0].duplicate().move(validationLayer, ElementPlacement.PLACEATEND);

    // Reset layers locked values
    designLayer.locked = isDesignLayerLocked;
    boundaryLayer.locked = isBoundaryLayerLocked;

    var boundaryPathItem = validationLayer.pathItems[0];
    var designPathItem = validationLayer.pathItems[1];

    var boundaryPathItemPointsBeforeCount = boundaryPathItem.pathPoints.length;
    var designPathItemPointsBeforeCount = designPathItem.pathPoints.length;

    // deselect all current selections
    app.executeMenuCommand('deselectall');

    // select both paths
    boundaryPathItem.selected = true;
    designPathItem.selected = true;

    app.executeMenuCommand('group');

    app.executeMenuCommand('Live Pathfinder Minus Back');
    app.executeMenuCommand('expandStyle');

    // A valid design will not alter the two paths when using "Live Pathfinder Minus Back"
    // An invalid design will replace the midsole boundary path and the design path with each out of bounds section
    if (
      !validationLayer.groupItems[0] ||
      validationLayer.groupItems[0].pathItems.length !== 2 ||
      boundaryPathItemPointsBeforeCount !==
        validationLayer.groupItems[0].pathItems[0].pathPoints.length ||
      designPathItemPointsBeforeCount !==
        validationLayer.groupItems[0].pathItems[1].pathPoints.length
    ) {
      for (var index = 0; index < validationLayer.groupItems[0].pathItems.length; index++) {
        var invalidSectionPathItem = validationLayer.groupItems[0].pathItems[index];

        var errorColor = new CMYKColor();
        errorColor.cyan = 0;
        errorColor.magenta = 100;
        errorColor.yellow = 100;
        errorColor.black = 0;

        invalidSectionPathItem.filled = true;
        invalidSectionPathItem.fillColor = errorColor;

        invalidSectionPathItem.stroked = false;

        invalidSectionPathItem.selected = true;
      }

      return '{"error": "design does not fit inside of the boundary layer"}';
    }

    validationLayer.remove();
    return '{}';
  },

  captureLockViz() {
    var doc = app.activeDocument;

    // Read the lock and visibility settings
    var state = '';
    for (var index = 0; index < doc.layers.length; index++) {
      state += doc.layers[index].locked + ',';
      state += doc.layers[index].visible + ',';
      doc.layers[index].locked = false;
      doc.layers[index].visible = true;
    }

    return state.slice(0, -1);
  },

  restoreLockViz(state) {
    var doc = app.activeDocument;

    // Set the lock and visibility settings for each layer
    // (assumes that the layers haven't changed since calling lockVizState)
    var count = 0;
    var tokens = state.split(',');
    for (var index = 0; index < doc.layers.length; index++) {
      doc.layers[index].locked = tokens[count] === 'true';
      doc.layers[index].visible = tokens[count + 1] === 'true';
      count += 2;
    }
  },

  removeLayer(targetLayer, force) {
    var doc = app.activeDocument;

    // Check if we already have this layer...
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      var empty = layer.pageItems.length == 0;
      if (empty || force) {
        layer.remove();
      }
    }
  },

  setLayerLock(targetLayer, locked) {
    var doc = app.activeDocument;

    // Find results layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      layer.locked = locked;
    }
  },

  setLayerVisibility(targetLayer, visible) {
    var doc = app.activeDocument;

    // Find results layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      layer.visible = visible;
    }
  },

  moveLayerToBack(targetLayer) {
    var doc = app.activeDocument;

    // Find results layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      layer.move(doc, ElementPlacement.PLACEATEND);
    }
  },
  simplifyPaths(targetLayer) {
    var doc = app.activeDocument;

    // Find results layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      if (layer.pathItems.length > 0) {
        for (var index = 0; index < layer.pathItems.length; index++) {
          var pathItem = layer.pathItems[index];
          pathItem.selected = true;
          app.executeMenuCommand('simplify menu item');
        }
      } else {
        return '{"error": "no paths found on layer"}';
      }
    }
  },

  setPathsColor(targetLayer, red, green, blue) {
    var doc = app.activeDocument;

    // Find layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (!layer) {
      return '{"error": "target layer not found"}';
    }

    // Save current locked value
    var locked = layer.locked;
    layer.locked = false;

    // create new RGBColor instance and assign parsed values
    var strokeColor = new RGBColor();
    strokeColor.red = red;
    strokeColor.green = green;
    strokeColor.blue = blue;

    // Loop over all pathItems on the target layer and set new color
    for (var i = 0; i < layer.pathItems.length; i++) {
      var pathItem = layer.pathItems[i];

      pathItem.strokeColor = strokeColor;
    }

    // Reset locked value
    layer.locked = locked;
  },

  setPathsWidth(targetLayer, width) {
    var doc = app.activeDocument;

    // Find layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (!layer) {
      return '{"error": "target layer not found"}';
    }

    // Save current locked value
    var locked = layer.locked;
    layer.locked = false;

    // Loop over all pathItems on the target layer and set new color
    for (var i = 0; i < layer.pathItems.length; i++) {
      var pathItem = layer.pathItems[i];

      pathItem.strokeWidth = width;
    }

    // Reset locked value
    layer.locked = locked;
  },
};
