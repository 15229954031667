// eslint-disable-next-line import/no-extraneous-dependencies
import { type } from 'os';
import * as z from 'zod';

export const ArtifactCreate = z.object({
  name: z.string().min(2),
  fileType: z.string().max(4),
  contentType: z.string(),
  metadata: z
    .record(z.union([z.string(), z.number(), z.array(z.union([z.string(), z.number()]))]))
    .optional(), // non objects or non-object arrays
});

export const ArtifactCreateResponse = z.object({
  id: z.string().uuid(),
  location: z.string(),
});

export const Artifact = z.object({
  createTimestamp: z.string(),
  id: z.string().uuid(),
  name: z.string().min(2),
  type: z.string().max(4),
  url: z.string(),
  active: z.boolean(),
});

export const ArtifactList = z.array(Artifact);

// requests
export type ArtifactCreate = z.infer<typeof ArtifactCreate>;
export type Artifact = z.infer<typeof Artifact>;
export type ArtifactList = z.infer<typeof ArtifactList>;

// responses
export type ArtifactCreateResponse = z.infer<typeof ArtifactCreateResponse>;
