import { ITsai, AdobeCurve, mmToPt, ptToMm, AdobeCurvePoint } from '@nike.picc.dam/ts-ai';
import {
  adobePolylineToNectarSegment,
  ToString,
  colors,
  width as strokeWidth,
} from '@nike.picc.dam/nectar';
import { z } from 'zod';
import { AlgorithmSolveResponse } from '@nike.picc.dam/grasshopper-client';
import { tabOptions } from '../app/ai-algorithm-editor/constants';

export interface ITsaiService {
  getLayerNames(): Promise<string[]>;
  setWindowTitle(title: string): void;
  applyMidsoleBoundary(midsoleOutline?: AdobeCurve): Promise<void>;
  addLayer(layerName: string): Promise<void>;
  removeLayer(layerName: string, forceRemove: boolean): Promise<void>;
  createNewCircleOn(layerName: string, x: number, y: number, r: number): Promise<void>;
  selectLayer(layerName: string): Promise<void>;
  setupArtboard(midsoleOutline: AdobeCurve): Promise<void>;
  exportDesignPaths(layerNames: Array<string>): Promise<void>;
  validateLayers(layerNames: Array<string>): Promise<void>;
  lockLayer(layerName: string): Promise<unknown>;
  unlockLayer(layerName: string): Promise<unknown>;
  applyComputedPath(
    layerName: string,
    vertices: AlgorithmSolveResponse['Vertices'],
    color: colors,
    width: strokeWidth
  ): Promise<void>;
  readXmp(key: string): Promise<any>;
  writeXmp(key: string, data: string): void;
  getPaths(layerNames: Array<string>): Promise<any[]>;
  getParsedPath(layerName: string): Promise<AdobeCurve[]>;
  setPathsColor(layerName: string, color: colors): Promise<void>;
  setPathsWidth(layerName: string, width: strokeWidth): Promise<void>;
  getPinchAndSpreadCenters(layerName: string): Promise<string>;
  openExportDialog(action: string): any;
  exportTxtFile(path: string, data: string): void;
  getSelection(): Promise<string>;
}

export class TsaiService implements ITsaiService {
  private tsai;

  constructor(tsai: ITsai) {
    this.tsai = tsai;
  }

  exportTxtFile(path: string, data: string): void {
    return this.tsai.exportTxtFile(path, data);
  }

  writeXmp(key: string, data: string): void {
    return this.tsai.writeXmp(key, data);
  }

  readXmp(key: string): Promise<any> {
    return this.tsai.readXmp(key);
  }

  getLayerNames(): Promise<string[]> {
    return this.tsai.getLayerNames();
  }

  openExportDialog(action: string): Promise<string> {
    return this.tsai.openExportDialog(action);
  }

  getSelection(): Promise<string> {
    return this.tsai.getSelection();
  }

  setWindowTitle(title: string): void {
    this.tsai.setWindowTitle(title);
  }

  async lockLayer(layerName: string): Promise<unknown> {
    const result = await this.tsai.lockLayer(layerName);
    return result;
  }

  async unlockLayer(layerName: string): Promise<unknown> {
    const result = await this.tsai.unlockLayer(layerName);
    return result;
  }

  async getPoint(layerName: string): Promise<unknown> {
    const result = await this.tsai.getPaths(layerName);
    return result;
  }

  async applyMidsoleBoundary(midsoleOutline: AdobeCurve): Promise<void> {
    const midsoleLayerName = 'Midsole Zone';
    await this.addLayer(midsoleLayerName);
    const layerInfo = JSON.parse(await this.tsai.getInfoFor(midsoleLayerName));
    if (layerInfo.pathItems === 0) {
      // 2.834645 are the number of points in 1 mm in Adobe.
      // TODO: abstract this behind ts-ai
      const width = 350 * 2.834645;
      const height = 350 * 2.834645;
      await this.tsai.resizeArtboard(width, height);
      await this.tsai.createNewPolylineOn(
        midsoleLayerName,
        midsoleOutline,
        true,
        colors.BLACK,
        strokeWidth.DEFAULT,
        ''
      );
    }
    await this.tsai.lockLayer(midsoleLayerName);
  }

  async addLayer(layerName: string, locked = false): Promise<void> {
    const layerNames = await this.tsai.getLayerNames();
    if (!layerNames.includes(layerName)) {
      await this.tsai.createLayer(layerName);
      if (locked) {
        await this.tsai.lockLayer(layerName);
      }
    } else {
      console.log(`Layer ${layerName} already exists`);
    }
  }

  async removeLayer(layerName: string, forceRemove = false): Promise<void> {
    const layerNames = await this.tsai.getLayerNames();
    if (layerNames.includes(layerName)) {
      await this.tsai.removeLayer(layerName, forceRemove);
    } else {
      console.log(`Layer ${layerName} does not exist`);
    }
  }

  async selectLayer(layerName: string): Promise<void> {
    const layerNames = await this.tsai.getLayerNames();
    if (layerNames.includes(layerName)) {
      await this.tsai.selectLayer(layerName);
    } else {
      console.log(`Layer ${layerName} does not exist`);
    }
  }

  // JNPR-69 TODO: pass this
  async setupArtboard(midsoleOutline: AdobeCurve): Promise<void> {
    await this.applyMidsoleBoundary(midsoleOutline);
    await this.removeLayer('Layer 1');
    await this.addLayer(tabOptions[0].label, true);
    await this.addLayer(tabOptions[1].label, true);
  }

  async validateLayer(layerName: string): Promise<void> {
    const validationResult = await this.tsai.validateLayer(layerName);
    const parsedValidationResult = JSON.parse(validationResult);

    if (parsedValidationResult.error) {
      throw new Error(parsedValidationResult.error);
    }
  }

  async getParsedPath(layerName: string): Promise<AdobeCurve[]> {
    const pathResult = await this.tsai.getPaths(layerName);
    const parsedPathResult = JSON.parse(pathResult);
    return parsedPathResult;
  }

  async getPaths(layerNames: Array<string>): Promise<any[]> {
    const allDesignPaths: any[] = [];

    const validationPromises: Promise<void>[] = [];
    const pathPromises: Promise<AdobeCurve[]>[] = [];

    layerNames.forEach(async layerName => {
      validationPromises.push(this.validateLayer(layerName));
    });
    await Promise.all(validationPromises);

    layerNames.forEach(async layerName => {
      pathPromises.push(this.getParsedPath(layerName));
    });
    await Promise.all(pathPromises)
      .then(paths => {
        paths.forEach(path => {
          allDesignPaths.push(path.map(x => adobePolylineToNectarSegment(x, 3, 0)));
        });
      })
      .catch(err => {
        throw err;
      });
    return allDesignPaths;
  }

  async validateLayers(layerNames: Array<string>): Promise<void> {
    const validationPromises: Promise<void>[] = [];

    layerNames.forEach(async layerName => {
      validationPromises.push(this.validateLayer(layerName));
    });
    await Promise.all(validationPromises);
  }

  async exportDesignPaths(layerNames: Array<string>): Promise<void> {
    let rawData = '';
    const allDesignPaths: any[] = [];

    const validationPromises: Promise<void>[] = [];
    const pathPromises: Promise<AdobeCurve[]>[] = [];

    layerNames.forEach(async layerName => {
      validationPromises.push(this.validateLayer(layerName));
    });
    await Promise.all(validationPromises);

    layerNames.forEach(async layerName => {
      pathPromises.push(this.getParsedPath(layerName));
    });
    await Promise.all(pathPromises)
      .then(paths => {
        paths.forEach(path => {
          allDesignPaths.push(path.map(x => adobePolylineToNectarSegment(x, 3, 0)));
        });
      })
      .catch(err => {
        throw err;
      });

    allDesignPaths.forEach(path => {
      rawData += ToString(path);
    });

    // TODO: create ts-ai functionality to export PathPoints for every point so they can all work in nectar
    try {
      const filePath = this.tsai.openExportDialog('Export Nectar Design for Print');
      // TODO: standardize format of data going in an out of AI
      // Saving as txt file for now, could easily move to JSON as payload grows more complex
      this.tsai.exportTxtFile(filePath.data, rawData);
    } catch (err) {
      // TODO: recover
      if (err instanceof z.ZodError) {
        console.log(err.issues);
      } else {
        console.log(err);
      }
    }
  }

  async applyComputedPath(
    layerName: string,
    vertices: AlgorithmSolveResponse['Vertices'],
    color: colors,
    width: strokeWidth
  ) {
    this.tsai.unlockLayer(layerName);

    this.tsai.clearLayer(layerName);

    const offsetX = 118 * mmToPt;
    const offsetY = -336 * mmToPt;

    const adobeCurve: AdobeCurve = {
      points: vertices.map(vertex => {
        const point = {
          x: vertex.X * mmToPt + offsetX,
          y: vertex.Y * mmToPt + offsetY,
        };

        return {
          anchor: point,
          leftDirection: point,
          rightDirection: point,
        };
      }),
      closed: false,
    };

    this.tsai.createNewPolylineOn(layerName, adobeCurve, false, color, width * mmToPt, '');
    // this.tsai.simplifyPaths(layerName);
    this.tsai.lockLayer(layerName);
  }

  async setPathsColor(layerName: string, color: colors): Promise<void> {
    const layerNames = await this.getLayerNames();
    if (!layerNames.includes(layerName)) {
      console.log(`Layer ${layerName} does not exist`);
      return;
    }
    await this.tsai.setPathsColor(layerName, color);
  }

  async setPathsWidth(layerName: string, width: strokeWidth): Promise<void> {
    const layerNames = await this.getLayerNames();
    if (!layerNames.includes(layerName)) {
      console.log(`Layer ${layerName} does not exist`);
      return;
    }
    await this.tsai.setPathsWidth(layerName, width);
  }

  async createNewCircleOn(layerName: string, x: number, y: number, r: number): Promise<void> {
    const layerNames = await this.getLayerNames();
    if (!layerNames.includes(layerName)) {
      await this.tsai.createLayer(layerName);
    }
    await this.tsai.createNewCircleOn(layerName, x * mmToPt, y * mmToPt, r * mmToPt);
  }

  async getPinchAndSpreadCenters(layerName: string): Promise<string> {
    const boundingBoxes = await this.tsai.getBoundingBoxes(layerName);
    const parsedBoundingBoxes = JSON.parse(boundingBoxes);

    const offsetX = -118 * mmToPt;
    const offsetY = 336 * mmToPt;

    const centers = [];
    for (let i = 0; i < parsedBoundingBoxes.length; i += 1) {
      const bb = parsedBoundingBoxes[i];

      const width = bb.r - bb.l;
      const height = bb.t - bb.b;

      const centerx = (bb.l + width / 2.0 + offsetX) * ptToMm;
      const centery = (bb.b + height / 2.0 + offsetY) * ptToMm;

      centers.push({ x: centerx, y: centery });
    }

    return JSON.stringify(centers);
  }
}
