import { AuroraClient, AuroraConfigOptions } from '../base/client';
import { MidsoleCreate, MidsoleCreateVersion, MidsoleGetResponse } from './types/midsole-types';

export const ASSET_CLASS = 'midsole3d';

// implementation of generic class
export class Midsole extends AuroraClient<MidsoleCreate, MidsoleCreateVersion, MidsoleGetResponse> {
  constructor(options: AuroraConfigOptions) {
    super(options, ASSET_CLASS, {
      // schema overrides
      Get: MidsoleGetResponse,
    });
  }
}
