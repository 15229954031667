/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Heading, Image, Flex, View } from '@adobe/react-spectrum';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import outsoleImage from '../../assets/outsole-select.png';
import horizontalCardPng from '../../assets/horizontal-card.png';
import horizontalCardSipingPng from '../../assets/horizontal-card-siping.png';
import { useTsai } from '../../providers/tsai-provider';
import NavigationHeader from '../nagivation-header/navigation-header';

/* eslint-disable-next-line */

export function OutsoleImage() {
  return <img src={outsoleImage} alt="Outsole" />;
}

// TODO: rebuild this with proper components not by hand, do we really need a picture with text?
export function HorizontalCard() {
  return <Image src={horizontalCardPng} alt="Horizontal Card UNFINISHED" />;
}

export function HomePage() {
  const navigate = useNavigate();
  const tsai = useTsai();

  const goToNectarPageMutation = useMutation(['goToNectarPage'], async () => {
    tsai.setWindowTitle('Additive Outsole');
    const currentLayers = await tsai.getLayerNames();
    if (currentLayers.includes('midsoleLayer')) {
      navigate('/nectar');
    } else {
      navigate('/midsole-page');
    }
  });

  return (
    <View minHeight="100vh" paddingX="size-250">
      <Flex direction="column">
        <NavigationHeader />
        <Heading level={1}>
          <div style={{ lineHeight: '28px' }}>
            Digital
            <br />
            Additive
            <br />
            Manufacturing
            <br />
          </div>
        </Heading>
        <Flex marginBottom="size-250" marginTop="size-250">
          Welcome to Digital Additive Manufacturing Design.
          <br />
          Click on Additive Outsoles below to get started.
        </Flex>
        <div onClick={() => goToNectarPageMutation.mutate()}>
          <Image src={horizontalCardPng} alt="Horizontal Card UNFINISHED" margin="size-100" />
        </div>
        <div
          onClick={() => {
            tsai.setWindowTitle('Siping');
            navigate('/siping');
          }}
        >
          <Image src={horizontalCardSipingPng} alt="Horizontal Card UNFINISHED" margin="size-100" />
        </div>
      </Flex>
    </View>
  );
}

export default HomePage;
