import produce, { current } from 'immer';
import {
  createContext,
  Dispatch,
  useReducer,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { colors, width as strokeWidth } from '@nike.picc.dam/nectar';
import { AlgorithmInput, AlgorithmSolveResponse } from '@nike.picc.dam/grasshopper-client';
import { useMutation } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import { useOktaAuth } from '@okta/okta-react';
import {
  LayerSettings,
  EditorReducerState,
  EditorReducerAction,
  ModifierSettings,
  AlgorithmSettings,
  AlgorithmSettingsMap,
  Layer,
} from './types';
import { useGrasshopperClient } from '../../providers/grasshopper-provider';
import { IGrasshopperClientService } from '../../lib/grasshopper';
import { useTsai } from '../../providers/tsai-provider';
import { tabOptions } from './constants';

function createDefaultLayerState(
  type: Layer,
  layerName: string,
  grasshopperClient: IGrasshopperClientService
): LayerSettings {
  const { algorithms } = grasshopperClient;

  const algorithmSettings = algorithms.reduce((acc, algorithm) => {
    const settings: AlgorithmSettingsMap = {
      ...acc,
      [algorithm.tag]: {
        type: algorithm.tag,
        name: algorithm.name,
        inputs: grasshopperClient
          .getAlgorithmControls(algorithm)
          .map(({ defaultValue, ...control }) => ({
            value: defaultValue,
            ...control,
          })),
        modifiers: [],
      },
    };

    return settings;
  }, {} as AlgorithmSettingsMap);

  let lineColor;
  let lineSize;

  if (layerName === tabOptions[0].label) {
    lineColor = colors.CLEAR;
    lineSize = strokeWidth.LARGE;
  } else {
    lineColor = colors.SIGNAL_BLUE;
    lineSize = strokeWidth.SMALL;
  }

  return {
    pinchAndSpreadCount: 0,
    status: 'disabled',
    name: layerName,
    freehandEnabled: false,
    syncEnabled: true,
    selectedAlgorithm: undefined,
    inputs: {
      color: lineColor,
      size: lineSize,
    },
    isMaterialSectionExpanded: true,
    isMidsoleSectionExpanded: true,
    ...algorithmSettings,
  };
}

type EditorReducerDispatch = Dispatch<EditorReducerAction>;
const EditorReducerContext = createContext<[EditorReducerState, EditorReducerDispatch] | null>(
  null
);

export const initEditorReducerProvider = (baseLayer: string, topLayer: string) =>
  function EditorReducerProvider({ children }: { children: ReactNode }) {
    const grasshopperClient = useGrasshopperClient();
    const tsai = useTsai();
    const { authState } = useOktaAuth();

    const baseLayerSettings =
      baseLayer.length > 2
        ? JSON.parse(atob(baseLayer))
        : createDefaultLayerState(tabOptions[0].id, tabOptions[0].label, grasshopperClient);
    const topLayerSettings =
      topLayer.length > 2
        ? JSON.parse(atob(topLayer))
        : createDefaultLayerState(tabOptions[1].id, tabOptions[1].label, grasshopperClient);

    const setPathsColorMutation = useMutation({
      mutationFn: async ({ state, color }: { state: EditorReducerState; color: colors }) => {
        const layer = state[state.selectedLayer];
        await tsai.setPathsColor(layer.name, color);
      },
    });

    const setPathsWidthMutation = useMutation({
      mutationFn: async ({ state, width }: { state: EditorReducerState; width: strokeWidth }) => {
        const layer = state[state.selectedLayer];
        await tsai.setPathsWidth(layer.name, width);
      },
    });

    const applyPathChangesMutation = useMutation({
      mutationFn: async ({
        data,
        state,
      }: {
        data: AlgorithmSolveResponse;
        state: EditorReducerState;
      }) => {
        const layer = state[state.selectedLayer];

        if (layer.syncEnabled) {
          await tsai.writeXmp(state.baseLayer.name, btoa(JSON.stringify(layer)));
          await tsai.applyComputedPath(
            state.baseLayer.name,
            data.Vertices,
            state.baseLayer.inputs.color,
            state.baseLayer.inputs.size
          );
          await tsai.writeXmp(state.topLayer.name, btoa(JSON.stringify(layer)));
          await tsai.applyComputedPath(
            state.topLayer.name,
            data.Vertices,
            state.topLayer.inputs.color,
            state.topLayer.inputs.size
          );
        } else {
          await tsai.writeXmp(state.selectedLayer, btoa(JSON.stringify(layer)));
          await tsai.applyComputedPath(
            layer.name,
            data.Vertices,
            layer.inputs.color,
            layer.inputs.size
          );
        }
      },
    });

    const solveAlgorithmMutation = useMutation({
      mutationKey: ['solve-algorithm'],
      mutationFn: async ({
        state,
        authToken,
      }: {
        state: EditorReducerState;
        authToken: string;
      }) => {
        const layer = state[state.selectedLayer];
        let algorithm: AlgorithmSettings | undefined;

        if (layer.selectedAlgorithm) {
          algorithm = layer[layer.selectedAlgorithm];

          const boundary = await tsai.readXmp('midsoleBoundary').then(async mb => mb);
          const complete = {
            ...algorithm,
            inputs: [
              ...algorithm.inputs,
              {
                name: 'OffsetBoundary',
                tag: 'Dropdown',
                value: boundary,
                options: [],
              },
            ],
          };
          return grasshopperClient.solveAlgorithm(complete, authToken);
        }

        throw new Error('No algorithm found');
      },
    });

    const pinchAndSpreadAdditionMutation = useMutation({
      mutationFn: async (layerName: string) => {
        await tsai.createNewCircleOn(layerName, 0, 0, 15);
      },
    });

    const pinchAndSpreadRemovalMutation = useMutation({
      mutationFn: async (layerName: string) => {
        await tsai.removeLayer(layerName, true);
      },
    });

    const debouncedSolveRef = useRef(debounce(solveAlgorithmMutation.mutate, 500));

    // cleanup any potential still running debounced fns on unmount
    useEffect(
      () => () => {
        debouncedSolveRef.current.cancel();
      },
      []
    );

    const reducer = useReducer(
      produce((state: EditorReducerState, action: EditorReducerAction) => {
        const draftState = state;
        const layer = draftState[draftState.selectedLayer];

        let algorithm: AlgorithmSettings | undefined;
        let algorithmInput: AlgorithmInput | undefined;
        if (layer.selectedAlgorithm) {
          algorithm = layer[layer.selectedAlgorithm];
          if (action.type === 'SET_ALGORITHM_INPUT_VALUE') {
            algorithmInput = algorithm.inputs.find(input => input.name === action.name);
          }
        }

        let newModifier: ModifierSettings | undefined;
        if (algorithm && action.type === 'ADD_MODIFIER') {
          const modifierToAdd = grasshopperClient.modifiers.find(
            mod => mod.tag === action.modifierType
          );

          if (modifierToAdd) {
            let newName = modifierToAdd.name;
            if (action.modifierType === 'pinchSpread.ghx') {
              newName += ` ${layer.name} ${layer.pinchAndSpreadCount + 1}`;
            }

            newModifier = {
              name: newName,
              type: action.modifierType,
              isExpanded: true,
              inputs: grasshopperClient
                .getModifierControls(modifierToAdd)
                .map(({ defaultValue, ...control }) => ({
                  ...control,
                  value: defaultValue,
                })) as AlgorithmInput[],
            };

            if (action.modifierType === 'pinchSpread.ghx') {
              newModifier.inputs.push({ name: 'Origin', value: { X: 0, Y: 0 } });
            }
          }
        }

        let modifier: ModifierSettings | undefined;
        let modifierInput: AlgorithmInput | undefined;
        if (algorithm && 'index' in action) {
          modifier = algorithm.modifiers[action.index];
          if (!modifier) {
            throw new Error(`No modifier at index "${action.index}" found`);
          }

          if (action.type === 'SET_MODIFIER_INPUT_VALUE') {
            modifierInput = modifier.inputs.find(input => input.name === action.name);
          }
        }

        let baseAlgorithm: AlgorithmSettings | undefined;
        let baseAlgorithmInput: AlgorithmInput | undefined;
        let topAlgorithm: AlgorithmSettings | undefined;
        let topAlgorithmInput: AlgorithmInput | undefined;
        if (
          (layer.syncEnabled || action.type === 'TOGGLE_LAYER_SYNC') &&
          draftState.baseLayer.selectedAlgorithm
        ) {
          baseAlgorithm = draftState.baseLayer[draftState.baseLayer.selectedAlgorithm];
          if (action.type === 'SET_ALGORITHM_INPUT_VALUE') {
            baseAlgorithmInput = baseAlgorithm.inputs.find(input => input.name === action.name);
          }
        }
        if (
          (layer.syncEnabled || action.type === 'TOGGLE_LAYER_SYNC') &&
          draftState.topLayer.selectedAlgorithm
        ) {
          topAlgorithm = draftState.topLayer[draftState.topLayer.selectedAlgorithm];
          if (action.type === 'SET_ALGORITHM_INPUT_VALUE') {
            topAlgorithmInput = topAlgorithm.inputs.find(input => input.name === action.name);
          }
        }

        let baseModifier: ModifierSettings | undefined;
        let baseModifierInput: AlgorithmInput | undefined;
        let topModifier: ModifierSettings | undefined;
        let topModifierInput: AlgorithmInput | undefined;
        if (
          (layer.syncEnabled || action.type === 'TOGGLE_LAYER_SYNC') &&
          baseAlgorithm &&
          'index' in action
        ) {
          baseModifier = baseAlgorithm.modifiers[action.index];
          if (!baseModifier) {
            throw new Error(`No base modifier at index "${action.index}" found`);
          }

          if (action.type === 'SET_MODIFIER_INPUT_VALUE') {
            baseModifierInput = baseModifier.inputs.find(input => input.name === action.name);
          }
        }
        if (
          (layer.syncEnabled || action.type === 'TOGGLE_LAYER_SYNC') &&
          topAlgorithm &&
          'index' in action
        ) {
          topModifier = topAlgorithm.modifiers[action.index];
          if (!topModifier) {
            throw new Error(`No top modifier at index "${action.index}" found`);
          }

          if (action.type === 'SET_MODIFIER_INPUT_VALUE') {
            topModifierInput = topModifier.inputs.find(input => input.name === action.name);
          }
        }

        switch (action.type) {
          case 'SELECT_LAYER':
            draftState.selectedLayer = action.value;
            break;

          case 'TOGGLE_SECTION_IS_EXPANDED':
            if (action.section === 'material') {
              layer.isMaterialSectionExpanded = !layer.isMaterialSectionExpanded;
            } else {
              layer.isMidsoleSectionExpanded = !layer.isMidsoleSectionExpanded;
            }
            break;

          case 'SELECT_COLOR':
            layer.inputs.color = action.value;
            break;

          case 'SELECT_SIZE':
            layer.inputs.size = action.value;
            break;

          case 'SELECT_ALGORITHM':
            if (layer.syncEnabled) {
              draftState.baseLayer.selectedAlgorithm = action.value;
              draftState.topLayer.selectedAlgorithm = action.value;
              draftState.baseLayer.status = 'enabled';
              draftState.topLayer.status = 'enabled';
            } else {
              layer.selectedAlgorithm = action.value;
              layer.status = 'enabled';
            }
            break;

          case 'TOGGLE_FREEHAND':
            layer.freehandEnabled = !layer.freehandEnabled;
            break;

          case 'TOGGLE_LAYER_SYNC':
            draftState.baseLayer.syncEnabled = !draftState.baseLayer.syncEnabled;
            draftState.topLayer.syncEnabled = !draftState.topLayer.syncEnabled;

            if (layer.syncEnabled && baseAlgorithm) {
              draftState.topLayer.selectedAlgorithm = draftState.baseLayer.selectedAlgorithm;
              if (draftState.topLayer.selectedAlgorithm) {
                topAlgorithm = draftState.topLayer[draftState.topLayer.selectedAlgorithm];
                topAlgorithm.inputs = baseAlgorithm.inputs;
              }
              if (baseAlgorithm && topAlgorithm) {
                topAlgorithm.modifiers = baseAlgorithm.modifiers;
              }
            }

            break;

          case 'SET_ALGORITHM_INPUT_VALUE':
            if (!algorithm) {
              throw new Error('No selected algorithm');
            }

            if (!algorithmInput) {
              throw new Error(
                `Input "${action.name}" does not exist on algorithm "${algorithm.type}"`
              );
            }
            if (layer.syncEnabled) {
              if (baseAlgorithmInput) {
                baseAlgorithmInput.value = action.value;
              }
              if (topAlgorithmInput) {
                topAlgorithmInput.value = action.value;
              }
            } else {
              algorithmInput.value = action.value;
            }

            break;

          case 'ADD_MODIFIER':
            if (!algorithm) {
              throw new Error('No selected algorithm');
            }

            if (!newModifier) {
              throw new Error(`Could not create modifier of type "${action.modifierType}"`);
            }

            if (newModifier.type === 'pinchSpread.ghx') {
              pinchAndSpreadAdditionMutation.mutate(newModifier.name);
              layer.pinchAndSpreadCount += 1;
            }

            if (layer.syncEnabled) {
              baseAlgorithm?.modifiers.push(newModifier);
              topAlgorithm?.modifiers.push(newModifier);
            } else {
              algorithm.modifiers.push(newModifier);
            }
            break;

          case 'DELETE_MODIFIER':
            if (!algorithm) {
              throw new Error('No selected algorithm');
            }

            if (!modifier) {
              throw new Error(`No modifier at index "${action.index}" found`);
            }

            if (modifier.type === 'pinchSpread.ghx') {
              pinchAndSpreadRemovalMutation.mutate(modifier.name);
            }

            if (layer.syncEnabled) {
              baseAlgorithm?.modifiers.splice(action.index, 1);
              topAlgorithm?.modifiers.splice(action.index, 1);
            } else {
              algorithm.modifiers.splice(action.index, 1);
            }
            break;

          case 'TOGGLE_MODIFIER_IS_EXPANDED':
            if (!algorithm) {
              throw new Error('No selected algorithm');
            }

            if (!modifier) {
              throw new Error(`No modifier at index "${action.index}" found`);
            }

            modifier.isExpanded = !modifier.isExpanded;
            break;

          case 'SET_MODIFIER_INPUT_VALUE':
            if (!algorithm) {
              throw new Error('No selected algorithm');
            }

            if (!modifier) {
              throw new Error(`No modifier at index "${action.index}" found`);
            }

            if (!modifierInput) {
              throw new Error(
                `Input "${action.name}" does not exist on modifiers of type "${modifier.type}"`
              );
            }

            if (layer.syncEnabled) {
              if (!baseAlgorithm && !topAlgorithm) {
                throw new Error('No selected algorithm');
              }

              if (!baseModifier && !topModifier) {
                throw new Error(`No modifier at index "${action.index}" found`);
              }

              if (!baseModifierInput && !topModifierInput) {
                throw new Error(
                  `Input "${action.name}" does not exist on base modifiers of type "${modifier.type}"`
                );
              }
              // ESLint error
              if (baseModifierInput && topModifierInput) {
                baseModifierInput.value = action.value;
                topModifierInput.value = action.value;
              }
            } else {
              modifierInput.value = action.value;
            }

            break;

          case 'SET_PINCH_AND_SPREAD_CENTER':
            if (!algorithm) {
              throw new Error('No selected algorithm');
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const mod of algorithm.modifiers) {
              if (mod.name === action.layerName) {
                mod.inputs.find(input => input.name === 'Origin').value = action.center;
              }
            }
            break;

          default:
            // eslint-disable-next-line no-case-declarations
            const exhaustive: never = action;
            break;
        }

        const currentState = current(draftState);

        if (
          action.type === 'SELECT_ALGORITHM' ||
          action.type === 'ADD_MODIFIER' ||
          action.type === 'DELETE_MODIFIER' ||
          (action.type === 'TOGGLE_LAYER_SYNC' && layer.syncEnabled)
        ) {
          solveAlgorithmMutation.mutate(
            {
              state: currentState,
              authToken: authState?.accessToken?.accessToken || '',
            },
            {
              onSuccess: data => {
                applyPathChangesMutation.mutate({ data, state: currentState });
              },
            }
          );
        }

        if (
          action.type === 'SET_ALGORITHM_INPUT_VALUE' ||
          action.type === 'SET_MODIFIER_INPUT_VALUE'
        ) {
          debouncedSolveRef.current(
            {
              state: currentState,
              authToken: authState?.accessToken?.accessToken || '',
            },
            {
              onSuccess: data => {
                applyPathChangesMutation.mutate({ data, state: currentState });
              },
            }
          );
        }

        if (action.type === 'SELECT_COLOR') {
          setPathsColorMutation.mutate({ state: currentState, color: action.value });
        }

        if (action.type === 'SELECT_SIZE') {
          setPathsWidthMutation.mutate({ state: currentState, width: action.value });
        }

        return draftState;
      }),
      {
        selectedLayer: 'baseLayer',
        baseLayer: baseLayerSettings,
        topLayer: topLayerSettings,
      }
    );

    return (
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <EditorReducerContext.Provider value={reducer}>{children}</EditorReducerContext.Provider>
    );
  };

export function useEditorReducer() {
  const editorReducerContext = useContext(EditorReducerContext);
  if (!editorReducerContext) {
    throw new Error('"useEditorReducer" must be used inside of an "EditorReducerContext" provider');
  }

  return editorReducerContext;
}
