import { AxiosError } from 'axios';

export class ServiceError extends Error {
  public readonly details;

  constructor(error: AxiosError) {
    super();
    this.message = error.message;
    this.details = {
      type: 'DOWNSTREAM_SERVICE_ERROR',
      url: `${error.config?.baseURL}${error.config?.url}`,
      statusCode: error.response?.status,
      httpResponse: error.response?.data,
    };
  }
}
