import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Divider, Flex, Heading, Slider, View } from '@adobe/react-spectrum';
import { useTsai } from '../../providers/tsai-provider';

import darkBladeSVG from '../../assets/dark-blade.svg';
import lightBladeSVG from '../../assets/light-blade.svg';
import surfaceSVG from '../../assets/surface.svg';

let sipingPaths: any[] = [];
let selectionJson = '{}';

export function SipingPage() {
  const tsai = useTsai();

  const [startDepth, setStartDepth] = useState<number>(0);
  const [endDepth, setEndDepth] = useState<number>(0);

  const [startAngle, setStartAngle] = useState<number>(0);
  const [endAngle, setEndAngle] = useState<number>(0);

  const [selectedPaths, setSelectedPaths] = useState({ count: 0, pathItems: [] });

  const queryClient = useQueryClient();
  const applyMidsoleMutation = useMutation(['applyMidsole'], () => tsai.applyMidsoleBoundary(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['layerNames']);
    },
  });

  const save = async () => {
    // Save the user settings to the AI document meta data
    const json = JSON.stringify(sipingPaths);
    await tsai.writeXmp('Siping', json);
  };

  const load = () => {
    // Load the user settings from the AI document meta data
    tsai.readXmp('Siping').then((xmpJson: string) => {
      if (xmpJson !== '{}') {
        const data = JSON.parse(xmpJson);
        sipingPaths = data;
      }
    });
  };

  const exportMachineCode = () => {
    tsai.openExportDialog('Export Siping Design for Print');
  };

  // On mount
  useEffect(() => {
    // Load any settings we already have for paths
    load();

    // Watch to see it the user has selected a different curve
    const timerId = setInterval(() => {
      tsai.getSelection().then((result: string) => {
        if (selectionJson !== result) {
          selectionJson = result;

          const selection = JSON.parse(result);
          setSelectedPaths(selection);

          if (selection.pathItems.length > 0) {
            // See if we have a new path
            selection.pathItems.forEach((pathItem: { uuid: string }) => {
              if (!sipingPaths.some(e => e.uuid === pathItem.uuid)) {
                sipingPaths.push({
                  uuid: pathItem.uuid,
                  startDepth: 0,
                  endDepth: 0,
                  startAngle: 0,
                  endAngle: 0,
                });
              }
            });

            // Update the UI to match the selected
            const sipingPath = sipingPaths.find(e => e.uuid === selection.pathItems[0].uuid);
            setStartDepth(sipingPath.startDepth);
            setEndDepth(sipingPath.endDepth);
            setStartAngle(sipingPath.startAngle);
            setEndAngle(sipingPath.endAngle);
          }
        }
      });
    }, 250);

    // On unmount, remove our timer
    return () => {
      clearInterval(timerId);
    };
  }, []);

  // Store changes to startDepth, endDepth, startAngle, endAngle
  useEffect(() => {
    selectedPaths.pathItems.forEach((pathItem: { uuid: string }) => {
      const path = sipingPaths.find(e => e.uuid === pathItem.uuid);
      if (path) {
        path.startDepth = startDepth;
        path.endDepth = endDepth;
        path.startAngle = startAngle;
        path.endAngle = endAngle;
      }
    });
  }, [startDepth, endDepth, startAngle, endAngle]);

  // Define the header based on what paths the user has selected
  let header = <div>Select a path that you would like to use to cut the midsole.</div>;
  if (selectedPaths.count === 1) {
    header = <div>Set the depth and angle of the blade along the length of the selected path.</div>;
  } else if (selectedPaths.count > 1) {
    header = (
      <div>Set the depth and angle of the blade along the length of the selected paths.</div>
    );
  }

  // Position and rotate the blade images based on angle and depth
  const containerStyle: React.CSSProperties = {
    position: 'relative',
    height: '100px',
  };

  const startStyle: React.CSSProperties = {
    left: '50%',
    margin: 'none',
    position: 'absolute',
    transformOrigin: 'bottom',
    transform: `translate(-50%, ${-72 + startDepth}px) rotate(${startAngle}deg)`,
    zIndex: -1,
  };

  const endStyle: React.CSSProperties = {
    left: '50%',
    margin: 'none',
    position: 'absolute',
    transformOrigin: 'bottom',
    transform: `translate(-50%, ${-72 + endDepth}px) rotate(${endAngle}deg)`,
    zIndex: -1,
  };

  const surfaceStyle: React.CSSProperties = {
    left: '50%',
    marginTop: '-5px',
    marginBottom: '-15px',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    zIndex: -2,
  };

  // Build the main body
  let body = <div />;

  if (selectedPaths.pathItems.length !== 0) {
    // Determine if we have a closed path selected
    let closed = false;
    const temp: Array<{ closed: boolean }> = selectedPaths.pathItems;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < temp.length; index++) {
      if (temp[index].closed) {
        closed = true;
        break;
      }
    }

    if (closed) {
      body = (
        <Flex direction="column" alignItems="center">
          <Divider size="M" />

          <Heading level={4}>Set Path</Heading>
          <div style={containerStyle}>
            <picture>
              <source srcSet={darkBladeSVG} media="(prefers-color-scheme: dark)" />
              <img
                src={lightBladeSVG}
                style={startStyle}
                alt="Cutting blade"
                width="50px"
                height="100px"
              />
            </picture>
            <img
              src={surfaceSVG}
              style={surfaceStyle}
              alt="Surface of the midsole"
              width="300px"
              height="115px"
            />
          </div>
          <Slider
            label="Depth"
            value={startDepth}
            onChange={setStartDepth}
            onChangeEnd={save}
            minValue={0}
            maxValue={35}
            defaultValue={0}
          />
          <Slider
            label="Angle"
            value={startAngle}
            onChange={setStartAngle}
            onChangeEnd={save}
            minValue={-60}
            maxValue={60}
            defaultValue={0}
          />
        </Flex>
      );
    } else {
      body = (
        <Flex direction="column" alignItems="center">
          <Divider size="M" />

          <Heading level={4}>Start of Path</Heading>
          <div style={containerStyle}>
            <picture>
              <source srcSet={darkBladeSVG} media="(prefers-color-scheme: dark)" />
              <img
                src={lightBladeSVG}
                style={startStyle}
                alt="Cutting blade"
                width="50px"
                height="100px"
              />
            </picture>
            <img
              src={surfaceSVG}
              style={surfaceStyle}
              alt="Surface of the midsole"
              width="300px"
              height="115px"
            />
          </div>
          <Slider
            label="Start Depth"
            value={startDepth}
            onChange={setStartDepth}
            onChangeEnd={save}
            minValue={0}
            maxValue={35}
            defaultValue={0}
          />
          <Slider
            label="Start Angle"
            value={startAngle}
            onChange={setStartAngle}
            onChangeEnd={save}
            minValue={-60}
            maxValue={60}
            defaultValue={0}
          />

          <Heading level={4}>End of Path</Heading>
          <div style={containerStyle}>
            <picture>
              <source srcSet={darkBladeSVG} media="(prefers-color-scheme: dark)" />
              <img
                src={lightBladeSVG}
                style={endStyle}
                alt="Cutting blade"
                width="50px"
                height="100px"
              />
            </picture>
            <img
              src={surfaceSVG}
              style={surfaceStyle}
              alt="Surface of the midsole"
              width="300px"
              height="115px"
            />
          </div>
          <Slider
            label="End Depth"
            value={endDepth}
            onChange={setEndDepth}
            onChangeEnd={save}
            minValue={0}
            maxValue={35}
            defaultValue={0}
          />
          <Slider
            label="End Angle"
            value={endAngle}
            onChange={setEndAngle}
            onChangeEnd={save}
            minValue={-60}
            maxValue={60}
            defaultValue={0}
          />
        </Flex>
      );
    }
  }

  return (
    <View minHeight="100vh">
      <Flex direction="column" gap="size-100">
        <View paddingX="size-250">
          <Heading level={2}>Siping</Heading>
          {header}
          <Flex direction="column" gap="size-100" marginTop="10px">
            <Button
              variant="cta"
              onPress={() => {
                applyMidsoleMutation.mutate();
              }}
            >
              Create Midsole Layer
            </Button>
            <Button variant="cta" onPress={exportMachineCode}>
              Export Machine Code
            </Button>
          </Flex>
        </View>
        {body}
      </Flex>
    </View>
  );
}

export default SipingPage;
