// Accepts a Function and args and will create the extendscript execution string
// ok to disable check for ban-types here bc we don't care about the function type, just that
// we can turn it into a string

import { AdobeCurve } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
export const createStringFrom = (func: Function, ...args: any[]): string => {
  const functionAsString = `function ${func.toString()};`;
  const functionArgs = args.reduce((acc, curr) => {
    if (typeof curr === 'string' || curr instanceof String) {
      // If this argument is a string, warp with 's (this is need because passing JSON will include "s)
      return `${acc} '${curr}',`;
    }
    if (typeof curr === 'number' || curr instanceof Number) {
      return `${acc} ${curr},`;
    }
    if (typeof curr === 'boolean' || curr instanceof Boolean) {
      return `${acc} ${curr},`;
    }
    // TODO: handle objects
    return `${acc}`;
  }, '');
  const extendscriptString = `${functionAsString} ${func.name}(${functionArgs
    .trim()
    .slice(0, -1)});`;
  return extendscriptString;
};

// deconstructs an AdobeCurve into a comma delimited string for xs
export const convertToExtendScriptPointFormat = (line: AdobeCurve): string =>
  line.points.reduce((acc, curr) => `${acc}${curr.anchor.x},${curr.anchor.y},`, '').slice(0, -1);
