import { Flex, Picker, Item, Divider } from '@adobe/react-spectrum';
import { useGrasshopperClient } from '../../providers/grasshopper-provider';
import { AlgorithmInputs } from './algorithm-inputs';
import { tabOptions } from './constants';
import { useEditorReducer } from './editor-reducer';
import { InputItem, InputLabel } from './input';
import { ModifierPanel } from './modifier-panel';

export function MidsoleInputs() {
  const [state, dispatch] = useEditorReducer();
  const grasshopperClient = useGrasshopperClient();

  const layer = state[state.selectedLayer];
  const algorithm = layer.selectedAlgorithm ? layer[layer.selectedAlgorithm] : undefined;

  return (
    <Flex direction="column" flex="1" gap="size-250">
      <InputItem>
        <InputLabel id="algorithm-selector-label">Base</InputLabel>
        <Picker
          data-testid="algorithm-selector"
          items={grasshopperClient.algorithms}
          selectedKey={layer.selectedAlgorithm}
          aria-labelledby="algorithm-selector-label"
          placeholder="Select algorithm..."
          isDisabled={layer.name === tabOptions[1].label && layer.syncEnabled}
          onSelectionChange={value => {
            dispatch({
              type: 'SELECT_ALGORITHM',
              value: value as typeof grasshopperClient.algorithms[number]['tag'],
            });
          }}
        >
          {item => <Item key={item.tag}>{item.name}</Item>}
        </Picker>
      </InputItem>
      {algorithm && layer.status === 'enabled' ? <AlgorithmInputs /> : null}
      <InputItem>
        <InputLabel id="modifier-selector-label">Modifier</InputLabel>
        <Picker
          items={grasshopperClient.modifiers}
          selectedKey={null}
          isDisabled={
            (layer.name === tabOptions[1].label && layer.syncEnabled) || layer.status === 'disabled'
          }
          aria-labelledby="modifier-selector-label"
          placeholder={algorithm?.modifiers.length === 0 ? 'Select modifier...' : 'Add modifier...'}
          onSelectionChange={value => {
            dispatch({
              type: 'ADD_MODIFIER',
              modifierType: value as typeof grasshopperClient.modifiers[number]['tag'],
            });
          }}
        >
          {item => <Item key={item.tag}>{item.name}</Item>}
        </Picker>
      </InputItem>
      {algorithm?.modifiers.length ? (
        <Flex direction="column" gap="size-125">
          {algorithm.modifiers.map((modifier, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ModifierPanel key={`${modifier.type}-${index}`} index={index} modifier={modifier} />
          ))}
        </Flex>
      ) : null}
      <Divider size="S" marginTop="size-300" />
    </Flex>
  );
}
