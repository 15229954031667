/* eslint-disable no-use-before-define */
import { AuroraClient, AuroraConfigOptions } from './base/client';
import { AssetCreate, AssetCreateVersion, AssetGetResponse } from './base/types/asset';
import { Midsole } from './assets/midsole';
import { ComputationDefinition } from './assets/computationdefinition';

export class Aurora
  implements Record<keyof Aurora, AuroraClient<AssetCreate, AssetCreateVersion, AssetGetResponse>>
{
  public readonly Midsole: Midsole;

  public readonly ComputationDefinition: ComputationDefinition;

  constructor(options: AuroraConfigOptions) {
    // initialize asset client implementations
    this.Midsole = new Midsole(options);
    this.ComputationDefinition = new ComputationDefinition(options);
  }
}
