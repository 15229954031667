import { ReactNode } from 'react';
import { Flex, Heading, ActionButton } from '@adobe/react-spectrum';
import AddIcon from '@spectrum-icons/workflow/Add';
import RemoveIcon from '@spectrum-icons/workflow/Remove';

type CollapsibleSectionProps = {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  children: ReactNode;
};

export function CollapsibleSection({
  title,
  isExpanded,
  onToggle,
  children,
}: CollapsibleSectionProps) {
  return (
    <div>
      <Flex alignItems="center">
        <Heading level={4} flexGrow={1} UNSAFE_style={{ fontWeight: 400 }}>
          {title}
        </Heading>
        <ActionButton isQuiet isDisabled={false} onPress={onToggle}>
          {isExpanded ? <RemoveIcon /> : <AddIcon />}
        </ActionButton>
      </Flex>
      {isExpanded ? children : null}
    </div>
  );
}
