import { createContext, useContext } from 'react';
import { IGrasshopperClientService } from '../lib/grasshopper';

export const GrasshopperClientContext = createContext<IGrasshopperClientService | null>(null);

export function GrasshopperClientProvider({
  children,
  service,
}: {
  children: React.ReactNode;
  service: IGrasshopperClientService;
}) {
  return (
    <GrasshopperClientContext.Provider value={service}>
      {children}
    </GrasshopperClientContext.Provider>
  );
}

export function useGrasshopperClient() {
  const grasshopperClient = useContext(GrasshopperClientContext);

  if (!grasshopperClient) {
    throw new Error('"useGrasshopperClient" must be used within a "GrasshopperClientProvider"');
  }

  return grasshopperClient;
}
