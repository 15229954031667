/* eslint-disable max-classes-per-file */
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AdobeCurve } from '@nike.picc.dam/ts-ai';
import { z } from 'zod';

export function nectar(): string {
  return 'nectar';
}

const vtxSchema = z.object({
  x: z.number(),
  y: z.number(),
  z: z.number(),
});

export type vtx = z.infer<typeof vtxSchema>;

export const SegmentSchema = z.object({
  path: vtxSchema.array().min(2),
  width: z.number(),
  extruder: z.number().gte(0).lte(1),
});

export type Segment = z.infer<typeof SegmentSchema>;

export const adobePolylineToNectarSegment = (path: AdobeCurve, width: number, extruder: number) =>
  SegmentSchema.parse({
    path: path.points.map(x => ({ ...x.anchor, z: 0 })),
    width,
    extruder,
  });

function ToStringImpl(segments: Segment[]): string {
  let output = '';

  output += `1\n`; // version

  output += `${segments.length}\n`; // num segments
  segments.forEach((segment: Segment) => {
    output += `${segment.path.length}\n`; // num vertexes
    output += `${segment.width}\n`;
    output += `${segment.extruder}\n`;

    segment.path.forEach((vertex: vtx) => {
      output += `${vertex.x} ${vertex.y} ${vertex.z}\n`;
    });
  });

  return output;
}

export const ToString = z.function().args(SegmentSchema.array().min(1)).implement(ToStringImpl);

function DuplicateSegmentsImpl(segments: Segment[], targetExtruder: number): Segment[] {
  const newSegments = segments.map(x => {
    const y = JSON.parse(JSON.stringify(x));
    y.extruder = targetExtruder;
    return y;
  });
  return newSegments;
}

export const DuplicateSegments = z
  .function()
  .args(SegmentSchema.array(), z.number())
  .implement(DuplicateSegmentsImpl);

// TODO: create function that calculates the height changes for
// the topPath where it crosses/intersect over the bottom path
