// eslint-disable-next-line no-shadow
export enum colors {
  BLACK = '0, 0, 0',
  RED = '255, 0, 0',
  GREEN = '0, 255, 0',
  BLUE = '0, 0, 255',
  BRIGHT_CRIMSON = '220, 60, 68',
  GREEN_GLOW = '132, 206, 164',
  GUM_LIGHT_BROWN = '206, 170, 121',
  SIGNAL_BLUE = '0, 101, 178',
  CLEAR = '220, 220, 220',
}
