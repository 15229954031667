/* eslint-disable */
export const exportCurves = {
  getAIPaths(targetLayer) {
    var doc = app.activeDocument;

    // Find the desired layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    // Read the curves from the Nectar layer
    var result = '[';

    if (layer) {
      for (var i = 0; i < layer.pathItems.length; i++) {
        var pathString = '';
        var pathItem = layer.pathItems[i];

        for (var j = 0; j < pathItem.pathPoints.length; j++) {
          var pathPoint = pathItem.pathPoints[j];

          pathString += '{';
          pathString +=
            '"anchor":{"x":' + pathPoint.anchor[0] + ',"y":' + pathPoint.anchor[1] + '},';
          pathString +=
            '"leftDirection":{"x":' +
            pathPoint.leftDirection[0] +
            ',"y":' +
            pathPoint.leftDirection[1] +
            '},';
          pathString +=
            '"rightDirection":{"x":' +
            pathPoint.rightDirection[0] +
            ',"y":' +
            pathPoint.rightDirection[1] +
            '}';
          pathString += j === pathItem.pathPoints.length - 1 ? '}' : '},';
        }
        result += '{"closed":' + pathItem.closed + ', "points":[' + pathString;
        result += i === layer.pathItems.length - 1 ? ']}' : ']},';
      }
    }

    result += ']';

    // Test - save to file
    /*
    var saveFile = File(app.activeDocument.path + "/curves.json");
    if(saveFile.exists) saveFile.remove();
    saveFile.encoding = "UTF8";
    saveFile.open("e", "TEXT", "????");
    saveFile.writeln(result);
    saveFile.close();
    */

    return result;
  },

  getCanvasHeight() {
    return app.activeDocument.height;
  },

  getCanvasWidth() {
    return app.activeDocument.width;
  },

  getBoundingBoxes(targetLayer) {
    var doc = app.activeDocument;

    // Find the desired layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    var result = '[';

    if (layer) {
      for (var i = 0; i < layer.pathItems.length; i++) {
        var pathItem = layer.pathItems[i];

        if (pathItem.closed === false) {
          continue;
        }

        if (i !== 0) {
          result += ',';
        }

        var bounds = pathItem.geometricBounds;
        result += '{';
        result += '"l":' + bounds[0] + ',';
        result += '"t":' + bounds[1] + ',';
        result += '"r":' + bounds[2] + ',';
        result += '"b":' + bounds[3];
        result += '}';
      }
    }

    result += ']';

    return result;
  },
};
