import React from 'react';
import { ProgressCircle, Flex, View } from '@adobe/react-spectrum';
import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import AiAlgorithmEditor from './ai-algorithm-editor/ai-algorithm-editor';
import SipingPage from './siping-page/siping-page';
import AuthGuard from './auth-guard/auth-guard';
import HomePage from './home-page/home-page';
import MidsolePage from './midsole-selection-page/midsole-selection-page';

function App() {
  return (
    <Routes>
      <Route path="/" element={<AuthGuard />}>
        <Route path="" element={<HomePage />} />
        <Route path="nectar/*" element={<AiAlgorithmEditor />} />
        <Route path="midsole-page" element={<MidsolePage />} />
        <Route path="siping" element={<SipingPage />} />
      </Route>
      <Route
        path="/implicit/callback"
        element={
          <LoginCallback
            loadingElement={
              <View minHeight="100vh">
                <Flex direction="column" gap="size-100">
                  <Flex marginTop="size-25" direction="column" gap="size-500" alignItems="center">
                    <ProgressCircle aria-label="Loading…" isIndeterminate />
                  </Flex>
                </Flex>
              </View>
            }
          />
        }
      />
    </Routes>
  );
}

export default App;
