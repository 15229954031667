import { AuroraClient, AuroraConfigOptions } from '../base/client';
import {
  ComputationDefinitionCreate,
  ComputationDefinitionCreateVersion,
  ComputationDefinitionGetResponse,
} from './types/computationdefinition-types';

export const ASSET_CLASS = 'computationdefinition';

// implementation of generic class
export class ComputationDefinition extends AuroraClient<
  ComputationDefinitionCreate,
  ComputationDefinitionCreateVersion,
  ComputationDefinitionGetResponse
> {
  constructor(options: AuroraConfigOptions) {
    super(options, ASSET_CLASS, {
      // schema overrides
      Get: ComputationDefinitionGetResponse,
    });
  }
}
