import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import { ProgressCircle, View, Flex } from '@adobe/react-spectrum';

/* eslint-disable-next-line */
export interface AuthGuardProps {}

export function AuthGuard(props: AuthGuardProps) {
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState || !authState?.isAuthenticated) {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();

    return (
      <View minHeight="100vh">
        <Flex direction="column" gap="size-100">
          <Flex marginTop="size-25" direction="column" gap="size-500" alignItems="center">
            <ProgressCircle aria-label="Loading…" isIndeterminate />
          </Flex>
        </Flex>
      </View>
    );
  }

  return <Outlet />;
}

export default AuthGuard;
