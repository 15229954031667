import { Item, Tabs, TabList, TabPanels, View, Flex, Header, Switch } from '@adobe/react-spectrum';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import NavigationHeader from '../nagivation-header/navigation-header';
import { initEditorReducerProvider, useEditorReducer } from './editor-reducer';
import { tabOptions } from './constants';
import { useTsai } from '../../providers/tsai-provider';
import { EditorForm } from './editor-form';

const backgroundColor = 'gray-100';

function EditorTabs() {
  const [state, dispatch] = useEditorReducer();
  const tsai = useTsai();
  const layer = state[state.selectedLayer];

  const [windowScroll, setWindowScroll] = useState(window.scrollY);

  useEffect(() => {
    // track window scroll for updating box
    // shadow on sticky elements
    // /////////////////////////////////
    function onScroll(event: Event) {
      setWindowScroll(window.scrollY);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useQuery(['select-active-layer-query', state.selectedLayer], () => {
    tsai.selectLayer(layer.name);
  });

  return (
    <View minHeight="100vh" paddingX="size-250" backgroundColor={backgroundColor}>
      <Flex direction="column">
        <NavigationHeader />
        <Tabs
          aria-label="Layer Settings"
          density="compact"
          selectedKey={state.selectedLayer}
          onSelectionChange={value => {
            dispatch({
              type: 'SELECT_LAYER',
              value: value as typeof tabOptions[number]['id'],
            });
          }}
        >
          <TabList
            position="sticky"
            top="size-500"
            zIndex={10}
            UNSAFE_style={{
              paddingLeft:
                'var(--spectrum-global-dimension-size-250, var(--spectrum-alias-size-250))',
              paddingRight:
                'var(--spectrum-global-dimension-size-250, var(--spectrum-alias-size-250))',
              backgroundColor: `var(--spectrum-global-color-${backgroundColor})`,
              boxShadow: `0px 10px 8px 2px rgba(0, 0, 0, ${windowScroll > 0 ? 0.15 : 0})`,
            }}
          >
            {tabOptions.map(option => (
              <Item key={option.id}>{option.label}</Item>
            ))}
          </TabList>
          <TabPanels>
            {tabOptions.map(option => (
              <Item key={option.id} textValue={option.label}>
                <View paddingX="size-250">
                  <EditorForm />
                </View>
              </Item>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    </View>
  );
}

function AiAlgorithmEditor() {
  const tsai = useTsai();

  const [baseLayer, setBaseLayer] = useState('');
  const [topLayer, setTopLayer] = useState('');

  useEffect(() => {
    const fetchLayerSaveState = async (key: string) => tsai.readXmp(key);

    fetchLayerSaveState('baseLayer').then(x => {
      setBaseLayer(x);
    });
    fetchLayerSaveState('topLayer').then(x => {
      setTopLayer(x);
    });
  });

  const EditorReducerProvider = initEditorReducerProvider(baseLayer, topLayer);

  return (
    <EditorReducerProvider>
      <EditorTabs />
    </EditorReducerProvider>
  );
}

export default AiAlgorithmEditor;
