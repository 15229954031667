import * as z from 'zod';

export const Vertex = z
  .object({
    X: z.number(),
    Y: z.number(),
    Z: z.number(),
  })
  .default({ X: 0, Y: 0, Z: 0 });

export const RhinoComputeOutputTypes = z.enum([
  'System.Int32',
  'Rhino.Geometry.Point3d',
  'System.Double',
  'System.String',
]);

// Low level output returned from rhino.compute/azuretest
export const RhinoResponseValue = z.object({
  values: z.array(
    z.object({
      ParamName: z.string().startsWith('RH_OUT:'),
      InnerTree: z.object({}).catchall(
        z.array(
          z.object({
            type: RhinoComputeOutputTypes,
            data: z.string(), // "{"X":89.043269745072763,"Y":33.081125153905312,"Z":0.0}"
          })
        )
      ),
    })
  ),
});
export type RhinoResponseValue = z.infer<typeof RhinoResponseValue>;

// algorithm outputs & modifier inputs share schema
export const AlgorithmSolveResponse = z.object({
  Vertices: z.array(Vertex),
  Indices: z.array(z.number()),
  Multipliers: z.array(z.number()),
  Side: z.optional(z.array(z.string())),
});

export const SwitchBack = z.object({
  filename: z.string().default('switchback.ghx'),
  inputs: z.object({
    Divisions: z.number().min(0.1).max(30).step(1.0).default(27),
    Offset: z.number().min(0.1).max(10).step(0.01).default(4.98),
    OffsetBoundary: z.string().default(''),
  }),
});

export const SplitSwitchBack = z.object({
  filename: z.literal('splitSwitchback.ghx'),
  inputs: z.object({
    RightDivisions: z.number().min(1).max(40).step(1.0).default(20),
    LeftDivisions: z.number().min(1).max(40).step(1.0).default(15),
    RightOffset: z.number().min(0.1).max(10).step(0.01).default(5.47),
    LeftOffset: z.number().min(0.1).max(10).step(0.01).default(1.67),
    RightBaseOffset: z.number().min(0.1).max(20).step(0.01).default(13.22),
    LeftBaseOffset: z.number().min(0.1).max(20).step(0.01).default(10.11),
    OffsetBoundary: z.string().default(''),
  }),
});

export const PinchAndSpread = z.object({
  filename: z.literal('pinchSpread.ghx'),
  inputs: z.intersection(
    z.object({
      Origin_Layer: Vertex,
      Radius: z.number().min(1).max(100).step(1.0).default(20.0),
      Strengths: z.number().min(-1).max(1).step(0.1).default(-0.68),
      Falloffs: z.number().min(0.1).max(1).step(0.01).default(0.52),
      Power: z.number().min(0.1).max(10).step(0.01).default(1.81),
      Smoothness: z.number().min(0.1).max(1.0).step(0.01).default(1.0),
      Sharpness: z.number().min(0.1).max(1.0).step(0.01).default(0.0),
    }),
    AlgorithmSolveResponse
  ),
});

export const Maelstrom = z.object({
  filename: z.literal('maelstrom.ghx'),
  inputs: z.intersection(
    z.object({
      Origin_Layer: Vertex,
      Radius1: z.number().min(1).max(100).step(1.0).default(1.0),
      Radius2: z.number().min(1).max(100).step(1.0).default(20.0),
      Rotation: z.number().min(-360).max(360).step(10.0).default(70.0),
    }),
    AlgorithmSolveResponse
  ),
});

export const ModifierBase = z.object({
  Data_Map: z
    .string()
    .default(
      'iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAAAAACPAi4CAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE7mlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNi4wLWMwMDIgMTE2LjE2NDc2NiwgMjAyMS8wMi8xOS0yMzoxMDowNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdEV2dD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlRXZlbnQjIiB4bWxuczpwaG90b3Nob3A9Imh0dHA6Ly9ucy5hZG9iZS5jb20vcGhvdG9zaG9wLzEuMC8iIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjIgKFdpbmRvd3MpIiB4bXA6Q3JlYXRlRGF0ZT0iMjAyMS0wOC0wMlQxNTo1NDo1MC0wNzowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAyMS0wOC0wMlQxNTo1NDo1MC0wNzowMCIgeG1wOk1vZGlmeURhdGU9IjIwMjEtMDgtMDJUMTU6NTQ6NTAtMDc6MDAiIGRjOmZvcm1hdD0iaW1hZ2UvcG5nIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOmYxMTEwNjY2LTI1YjQtOTk0Yi1iOTVhLWFmYmIzZmI0M2MzYiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpmMTExMDY2Ni0yNWI0LTk5NGItYjk1YS1hZmJiM2ZiNDNjM2IiIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpmMTExMDY2Ni0yNWI0LTk5NGItYjk1YS1hZmJiM2ZiNDNjM2IiIHBob3Rvc2hvcDpDb2xvck1vZGU9IjEiPiA8eG1wTU06SGlzdG9yeT4gPHJkZjpTZXE+IDxyZGY6bGkgc3RFdnQ6YWN0aW9uPSJjcmVhdGVkIiBzdEV2dDppbnN0YW5jZUlEPSJ4bXAuaWlkOmYxMTEwNjY2LTI1YjQtOTk0Yi1iOTVhLWFmYmIzZmI0M2MzYiIgc3RFdnQ6d2hlbj0iMjAyMS0wOC0wMlQxNTo1NDo1MC0wNzowMCIgc3RFdnQ6c29mdHdhcmVBZ2VudD0iQWRvYmUgUGhvdG9zaG9wIDIxLjIgKFdpbmRvd3MpIi8+IDwvcmRmOlNlcT4gPC94bXBNTTpIaXN0b3J5PiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgYWs5wAAAAsSURBVFiF7cyhAQAACMMw4P+f4YgZROqb3sqa8AcAAAAAAAAAAAAAAADwHjhQLQF/Scv1kwAAAABJRU5ErkJggg=='
    ),
  Phase: z.number().min(-3).max(3).step(0.01).default(3.0),
  Amplitude: z.number().min(0).max(10).step(0.1).default(1.9),
  Frequency: z.number().min(1.0).max(10).step(1.0).default(7.0),
});

export const Noise = z.object({
  filename: z.literal('noiseWave.ghx'),
  inputs: z.intersection(
    z.object({
      RandomSeed: z.number().min(1.0).max(100).step(1.0).default(50.0),
      Octaves: z.number().min(0.1).max(10).step(0.1).default(2.0),
      Lacunarity: z.number().min(0.1).max(10).step(0.1).default(2.8),
      Persistence: z.number().min(0.1).max(1.0).step(0.01).default(0.321),
    }),
    AlgorithmSolveResponse,
    ModifierBase
  ),
});

export const Square = z.object({
  filename: z.literal('squareWave.ghx'),
  inputs: z.object({
    ModifierBase,
    SquareWidth: z.number().min(0.1).max(10).step(0.1).default(0.5),
    AlgorithmSolveResponse,
  }),
});

export const Sine = z.object({
  filename: z.string().default('sineWave.ghx'),
  inputs: z.intersection(ModifierBase, AlgorithmSolveResponse),
});

export const Triangle = z.object({
  filename: z.literal('triangleWave.ghx'),
  inputs: z.object({
    ModifierBase,
    AlgorithmSolveResponse,
  }),
});

export const Sawtooth = z.object({
  filename: z.literal('sawtoothWave.ghx'),
  inputs: z.object({
    ModifierBase,
    SquareWidth: z.number().min(0.1).max(10).step(0.1).default(0.5),
    AlgorithmSolveResponse,
  }),
});

export type SwitchBack = z.infer<typeof SwitchBack>;
export type SplitSwitchBack = z.infer<typeof SplitSwitchBack>;
export type AlgorithmSolveResponse = z.infer<typeof AlgorithmSolveResponse>;
export type PinchAndSpread = z.infer<typeof PinchAndSpread>;
export type Maelstrom = z.infer<typeof Maelstrom>;
export type ModifierBase = z.infer<typeof ModifierBase>;
export type Noise = z.infer<typeof Noise>;
export type Square = z.infer<typeof Square>;
export type Sine = z.infer<typeof Sine>;
export type Triangle = z.infer<typeof Triangle>;
export type Sawtooth = z.infer<typeof Sawtooth>;
