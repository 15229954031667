import { Flex, Picker, Item, Text, View } from '@adobe/react-spectrum';
import { materialColorOptions, materialSizeOptions } from './constants';
import { useEditorReducer } from './editor-reducer';

export function MaterialInputs() {
  const [state, dispatch] = useEditorReducer();

  const layer = state[state.selectedLayer];

  return (
    <Flex gap="size-200" marginBottom="size-300">
      <Picker
        data-testid="color-selector"
        items={materialColorOptions}
        selectedKey={layer.inputs.color}
        label="Color"
        labelPosition="side"
        minWidth="215px"
        onSelectionChange={value => {
          dispatch({
            type: 'SELECT_COLOR',
            value: value as typeof materialColorOptions[number]['value'],
          });
        }}
      >
        {item => {
          const formattedColor = `rgb(${item.value})`;

          return (
            <Item key={item.value} textValue={item.value}>
              <View
                height="size-200"
                width="size-200"
                marginStart="size-50"
                marginY="size-100"
                UNSAFE_style={{
                  backgroundColor: formattedColor,
                  opacity: layer.status === 'disabled' ? '50%' : '100%',
                }}
              />
              <Text marginStart="size-200">{item.label}</Text>
            </Item>
          );
        }}
      </Picker>
      <Picker
        data-testid="size-selector"
        items={materialSizeOptions}
        selectedKey={String(layer.inputs.size)}
        label="Size"
        labelPosition="side"
        minWidth="128px"
        onSelectionChange={value => {
          dispatch({
            type: 'SELECT_SIZE',
            value: Number(value) as typeof materialSizeOptions[number]['value'],
          });
        }}
      >
        {item => (
          <Item key={item.value} textValue={String(item.value)}>
            <Text>{item.value}mm</Text>
          </Item>
        )}
      </Picker>
    </Flex>
  );
}
