import { z } from 'zod';
import { AssetCreate, AssetCreateVersion, AssetGetResponse } from '../../base/types/asset';

const SUB_CLASS = 'digitaladditivemanufacturing';
const RUNTIME = 'grasshopper';

// required asset metadata
const assetMetadata = z
  .object({
    subClass: z.literal(SUB_CLASS).default(SUB_CLASS),
    runtime: z.string().default(RUNTIME),
  })
  .default({});

// schemas
export const MidsoleCreate = AssetCreate.extend({
  assetMetadata,
});
export const MidsoleCreateVersion = AssetCreateVersion.extend({});
export const MidsoleGetResponse = AssetGetResponse.extend({ assetMetadata });

// types
export type MidsoleCreate = z.infer<typeof MidsoleCreate>;
export type MidsoleCreateVersion = z.infer<typeof MidsoleCreateVersion>;
export type MidsoleGetResponse = z.infer<typeof MidsoleGetResponse>;
