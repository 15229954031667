import { z } from 'zod';
import { ArtifactCreate } from '../base/types/artifact';

export const GeometryArtifactCreate = ArtifactCreate.extend({
  metadata: z.object({
    geometryType: z.enum(['vertices', 'mesh', 'surface']),
  }),
});

// requests
export type GeometryArtifactCreate = z.infer<typeof GeometryArtifactCreate>;
